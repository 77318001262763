import m from 'mithril';
import { apiService } from '../services/apiService';
import { appState } from '../lib/appState';
import { _t } from '../lib/i18n';
import sjefIconChevronsRight from '@sjefapp/sjeficons/icons/sjefIconChevronsRight';

let selectedOrderjob = {};
let payloadSplit = {};

const addOrderjobLine = (quantity, line, crossed = false) => {
    return {
        quantity: quantity,
        description: line.description,
        kitchen_groceries: line.kitchen_groceries,
        notes: line.notes,
        crossed: line.crossed || crossed,
    };
};

export class ModalOrderDetails {
    form = { status: '' };
    order = null;
    parentCloseModal;
    form = {};

    // orderSplit = {};

    oninit(vnode) {
        this.order = JSON.parse(JSON.stringify(vnode.attrs.order));

        if (typeof vnode.attrs.closeModal === 'function') {
            this.parentCloseModal = vnode.attrs.closeModal;
        }

        this.form = {
            id: this.order.id,
            status: this.order.attributes.status,
            lines: this.order.attributes.lines,
        };
    }

    async postOrderForSplit() {
        // Initialize the current and new lines arrays
        // const linesCurrent = [];
        // const linesNew = [];

        const payload = {
            status: this.form.status,
            lines_current: [],
            lines_new: [],
        };

        this.form.lines.forEach((line) => {
            if (line.quantity === 0 && line.quantity_new === 0) {
                return;
            }

            // Process lines with quantity greater than 0
            if (line.quantity > 0) {
                payload.lines_current.push(addOrderjobLine(line.quantity, line, false));
            }

            // Process lines with quantity_new greater than 0
            if (line.quantity_new > 0) {
                payload.lines_current.push(addOrderjobLine(line.quantity_new, line, true));
                payload.lines_new.push(addOrderjobLine(line.quantity_new, line));
            }
        });

        const response = await apiService.post(`orderjobs/${this.form.id}/split`, payload);

        if (response.error) {
            appState.toast('An error occurred');
            apiService.handleError('A0060', response.error);
            return response;
        }

        await appState.actions.reloadOrders();
    }

    // Render the view of the modal order details component
    view(vnode) {
        if (!this.order) {
            return null;
        }

        // selectedOrderjob = JSON.parse(JSON.stringify(order));

        // Render the Mithril virtual DOM structure for the modal
        return m('.modalContainer', { id: 'modalContainer' }, [
            m('.modalOverlay', {
                onclick: () => {
                    if (typeof this.parentCloseModal === 'function') {
                        this.parentCloseModal();
                    }
                },
            }),
            m('.modalDialog', [
                m(
                    '.modalHeader',
                    m('.flex.between', [
                        m('h3.modalTitle', [m('.modalId', this.order.attributes.order_label)]),
                        m('h3.modalTitle', [m('.modalId', this.order.attributes.table_label)]),
                    ])
                ),
                m('.modalBody', [
                    m(
                        'ul.modalAttributes',
                        this.form.lines.map((line, index) => {
                            return m('li.modalDescription1', { key: index, class: line.crossed ? 'crossed' : '' }, [
                                m(
                                    '.flex.start',
                                    m('', `${Math.round(line.quantity)} x - ${line.description}`),
                                    m(
                                        '.flex',
                                        !line.crossed
                                            ? [
                                                  m(
                                                      'button',
                                                      {
                                                          onclick: () => {
                                                              if (line.quantity === 0 || line.crossed) {
                                                                  return;
                                                              }

                                                              line.quantity--;

                                                              if (line.quantity_new === undefined) {
                                                                  line.quantity_new = 1;
                                                              } else {
                                                                  line.quantity_new++;
                                                              }
                                                              // m.redraw();
                                                          },
                                                      },
                                                      m(sjefIconChevronsRight)
                                                  ),
                                                  m('button', `${line.quantity_new || 0}`),
                                              ]
                                            : null
                                    )
                                ),

                                // Nested list of kitchen groceries
                                m(
                                    'ul',
                                    Object.entries(line.kitchen_groceries).map(([key, value]) => {
                                        return m('li.modalGroceries', [m('div.groceriesRow', `${key}: `), m('div.groceriesRow', ` ${value}`)]);
                                    })
                                ),
                                // List of notes for the line item
                                m('ul.notes', line.notes),
                            ]);
                        })
                    ),
                ]),
                // Modal footer with close button

                // create select box for split order -> payloadSplit.status
                m(
                    '.setStatus',
                    ['open', 'in preparation', 'ready', 'parked', 'served'].map((option) => {
                        return m(
                            '.selectOption',
                            {
                                class: this.form.status === option ? 'selected' : '',
                                onclick: () => {
                                    this.form.status = option;
                                    m.redraw(); // Ensure UI is updated
                                },
                            },
                            _t(`lang.status.${option.replace(/\s+/g, '')}`)
                        );
                    })
                ),

                m(
                    '.modalFooter',
                    m(
                        '.buttonFlexer',

                        m(
                            'button.modalCloseButton',
                            {
                                onclick: async () => {
                                    await this.postOrderForSplit();
                                    if (typeof this.parentCloseModal === 'function') {
                                        this.parentCloseModal();
                                    }
                                },
                            },
                            _t('lang.buttons.split')
                        ),
                        m(
                            'button.modalCloseButton',
                            {
                                onclick: () => {
                                    if (typeof this.parentCloseModal === 'function') {
                                        this.parentCloseModal();
                                    }
                                },
                            },
                            _t('lang.buttons.close')
                        )
                    )
                ),
            ]),
        ]);
    }
}
