import m from 'mithril';
import { App } from './components/app';

import { Login } from './pages/login';
import { KdsMode } from './pages/kdsMode';
import { ModeCustomer } from './pages/modeCustomer';
import { ModeKitchen } from './pages/modeKitchen';
import { appState } from './lib/appState';
import { ModeServe } from './pages/modeServe';

export default {
    Routes: () => {
        m.route(document.getElementById('main'), '/', {
            '/': {
                render: function () {
                    return m(Login, {class: 'login'});
                },
            },

            '/kdsMode': {
                render: function () {
                    return m(App, {class: 'kdsMode'}, m(KdsMode));
                },
            },

            '/modeKitchen': {
                render: function () {
                    return m(App, {class: 'modeKitchen' }, m(ModeKitchen));
                },
            },
            '/modeCustomer': {
                render: function () {
                    return m(App, { class: 'modeCustomer' }, m(ModeCustomer));
                },
            },
            '/modeServe': {
                render: function () {
                    return m(App, { class: 'modeServe '}, m(ModeServe ));
                },
            },
        });
    },
};
