import m from 'mithril';
import { authService } from '../services/authService';
import { _t } from '../lib/i18n';
import { appState } from '../lib/appState';

// review: all the pages are classes, but this one is an object. Why? Rewrite it as a class, so it's consistent with the other pages +

export class Login {
    constructor() {
        this.client_code = '';
        this.terminal_code = '';
        this.terminal_pincode = '';
    }

    // oninit() {
    //     console.log(i18next);
    // }

    submit = async () => {
        try {
            // Perform login
            const result = await authService.login(this.client_code, this.terminal_code, this.terminal_pincode);
            // console.log(result);

            if (result.error) {
                return appState.toast(result.error);
            }

            await appState.actions.init();

            m.route.set('/kdsMode');
        } catch (error) {
            appState.toast('Error during login:', error);
        }
    };

    handleInput(event, field) {
        this[field] = event.target.value;
    }

    view() {
        return m('.signin-container', [
            m('', [m('.signin-heading', _t('lang.headlines.login_to_your_kds'))]),
            m(
                'form',
                {
                    onsubmit: (event) => {
                        event.preventDefault();
                        this.submit();
                    },
                },
                [
                    m('.form-fields', [
                        m('input.client-code.input-field[type=text][placeholder=' + _t('lang.fields.bedrijfs_code') + '][autofocus][required]', {
                            value: this.client_code,
                            oninput: (event) => this.handleInput(event, 'client_code'),
                        }),
                        m('input.terminal-code.input-field[type=text][placeholder=' + _t('lang.fields.terminal_code') + '][required]', {
                            value: this.terminal_code,
                            oninput: (event) => this.handleInput(event, 'terminal_code'),
                        }),
                        m('input.pincode.input-field[type=password][placeholder=' + _t('lang.fields.pincode') + '][required]', {
                            value: this.terminal_pincode,
                            oninput: (event) => this.handleInput(event, 'terminal_pincode'),
                        }),
                    ]),
                    m('.signin-button', [m('button.submit-button[type=submit]', _t('lang.buttons.login'))]),
                ]
            ),
        ]);
    }
}

const main = document.getElementById('main');
m.mount(main, Login);
