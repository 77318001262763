/* tslint:disable */
export var ACTIVITY = 'activity';
export var AIRPLAY = 'airplay';
export var ALERT_CIRCLE = 'alert-circle';
export var ALERT_OCTAGON = 'alert-octagon';
export var ALERT_TRIANGLE = 'alert-triangle';
export var ALIGN_CENTER = 'align-center';
export var ALIGN_JUSTIFY = 'align-justify';
export var ALIGN_LEFT = 'align-left';
export var ALIGN_RIGHT = 'align-right';
export var ANCHOR = 'anchor';
export var APERTURE = 'aperture';
export var ARCHIVE = 'archive';
export var ARROW_DOWN_CIRCLE = 'arrow-down-circle';
export var ARROW_DOWN_LEFT = 'arrow-down-left';
export var ARROW_DOWN_RIGHT = 'arrow-down-right';
export var ARROW_DOWN = 'arrow-down';
export var ARROW_LEFT_CIRCLE = 'arrow-left-circle';
export var ARROW_LEFT = 'arrow-left';
export var ARROW_RIGHT_CIRCLE = 'arrow-right-circle';
export var ARROW_RIGHT = 'arrow-right';
export var ARROW_UP_CIRCLE = 'arrow-up-circle';
export var ARROW_UP_LEFT = 'arrow-up-left';
export var ARROW_UP_RIGHT = 'arrow-up-right';
export var ARROW_UP = 'arrow-up';
export var AT_SIGN = 'at-sign';
export var AWARD = 'award';
export var BAR_CHART_2 = 'bar-chart-2';
export var BAR_CHART = 'bar-chart';
export var BATTERY_CHARGING = 'battery-charging';
export var BATTERY = 'battery';
export var BELL_OFF = 'bell-off';
export var BELL = 'bell';
export var BLUETOOTH = 'bluetooth';
export var BOLD = 'bold';
export var BOOK_OPEN = 'book-open';
export var BOOK = 'book';
export var BOOKMARK = 'bookmark';
export var BOX = 'box';
export var BRIEFCASE = 'briefcase';
export var CALENDAR = 'calendar';
export var CAMERA_OFF = 'camera-off';
export var CAMERA = 'camera';
export var CAST = 'cast';
export var CHECK_CIRCLE = 'check-circle';
export var CHECK_SQUARE = 'check-square';
export var CHECK = 'check';
export var CHEVRON_DOWN = 'chevron-down';
export var CHEVRON_LEFT = 'chevron-left';
export var CHEVRON_RIGHT = 'chevron-right';
export var CHEVRON_UP = 'chevron-up';
export var CHEVRONS_DOWN = 'chevrons-down';
export var CHEVRONS_LEFT = 'chevrons-left';
export var CHEVRONS_RIGHT = 'chevrons-right';
export var CHEVRONS_UP = 'chevrons-up';
export var CHROME = 'chrome';
export var CIRCLE = 'circle';
export var CLIPBOARD = 'clipboard';
export var CLOCK = 'clock';
export var CLOUD_DRIZZLE = 'cloud-drizzle';
export var CLOUD_LIGHTNING = 'cloud-lightning';
export var CLOUD_OFF = 'cloud-off';
export var CLOUD_RAIN = 'cloud-rain';
export var CLOUD_SNOW = 'cloud-snow';
export var CLOUD = 'cloud';
export var CODE = 'code';
export var CODEPEN = 'codepen';
export var CODESANDBOX = 'codesandbox';
export var COFFEE = 'coffee';
export var COLUMNS = 'columns';
export var COMMAND = 'command';
export var COMPASS = 'compass';
export var COPY = 'copy';
export var CORNER_DOWN_LEFT = 'corner-down-left';
export var CORNER_DOWN_RIGHT = 'corner-down-right';
export var CORNER_LEFT_DOWN = 'corner-left-down';
export var CORNER_LEFT_UP = 'corner-left-up';
export var CORNER_RIGHT_DOWN = 'corner-right-down';
export var CORNER_RIGHT_UP = 'corner-right-up';
export var CORNER_UP_LEFT = 'corner-up-left';
export var CORNER_UP_RIGHT = 'corner-up-right';
export var CPU = 'cpu';
export var CREDIT_CARD = 'credit-card';
export var CROP = 'crop';
export var CROSSHAIR = 'crosshair';
export var DATABASE = 'database';
export var DELETE = 'delete';
export var DISC = 'disc';
export var DIVIDE_CIRCLE = 'divide-circle';
export var DIVIDE_SQUARE = 'divide-square';
export var DIVIDE = 'divide';
export var DOLLAR_SIGN = 'dollar-sign';
export var DOWNLOAD_CLOUD = 'download-cloud';
export var DOWNLOAD = 'download';
export var DRIBBBLE = 'dribbble';
export var DROPLET = 'droplet';
export var EDIT_2 = 'edit-2';
export var EDIT_3 = 'edit-3';
export var EDIT = 'edit';
export var EXTERNAL_LINK = 'external-link';
export var EYE_OFF = 'eye-off';
export var EYE = 'eye';
export var FACEBOOK = 'facebook';
export var FAST_FORWARD = 'fast-forward';
export var FEATHER = 'feather';
export var FIGMA = 'figma';
export var FILE_MINUS = 'file-minus';
export var FILE_PLUS = 'file-plus';
export var FILE_TEXT = 'file-text';
export var FILE = 'file';
export var FILM = 'film';
export var FILTER = 'filter';
export var FLAG = 'flag';
export var FOLDER_MINUS = 'folder-minus';
export var FOLDER_PLUS = 'folder-plus';
export var FOLDER = 'folder';
export var FRAMER = 'framer';
export var FROWN = 'frown';
export var GIFT = 'gift';
export var GIT_BRANCH = 'git-branch';
export var GIT_COMMIT = 'git-commit';
export var GIT_MERGE = 'git-merge';
export var GIT_PULL_REQUEST = 'git-pull-request';
export var GITHUB = 'github';
export var GITLAB = 'gitlab';
export var GLOBE = 'globe';
export var GRID = 'grid';
export var HARD_DRIVE = 'hard-drive';
export var HASH = 'hash';
export var HEADPHONES = 'headphones';
export var HEART = 'heart';
export var HELP_CIRCLE = 'help-circle';
export var HEXAGON = 'hexagon';
export var HOME = 'home';
export var IMAGE = 'image';
export var INBOX = 'inbox';
export var INFO = 'info';
export var INSTAGRAM = 'instagram';
export var ITALIC = 'italic';
export var KEY = 'key';
export var LAYERS = 'layers';
export var LAYOUT = 'layout';
export var LIFE_BUOY = 'life-buoy';
export var LINK_2 = 'link-2';
export var LINK = 'link';
export var LINKEDIN = 'linkedin';
export var LIST = 'list';
export var LOADER = 'loader';
export var LOCK = 'lock';
export var LOG_IN = 'log-in';
export var LOG_OUT = 'log-out';
export var MAIL = 'mail';
export var MAP_PIN = 'map-pin';
export var MAP = 'map';
export var MAXIMIZE_2 = 'maximize-2';
export var MAXIMIZE = 'maximize';
export var MEH = 'meh';
export var MENU = 'menu';
export var MESSAGE_CIRCLE = 'message-circle';
export var MESSAGE_SQUARE = 'message-square';
export var MIC_OFF = 'mic-off';
export var MIC = 'mic';
export var MINIMIZE_2 = 'minimize-2';
export var MINIMIZE = 'minimize';
export var MINUS_CIRCLE = 'minus-circle';
export var MINUS_SQUARE = 'minus-square';
export var MINUS = 'minus';
export var MONITOR = 'monitor';
export var MOON = 'moon';
export var MORE_HORIZONTAL = 'more-horizontal';
export var MORE_VERTICAL = 'more-vertical';
export var MOUSE_POINTER = 'mouse-pointer';
export var MOVE = 'move';
export var MUSIC = 'music';
export var NAVIGATION_2 = 'navigation-2';
export var NAVIGATION = 'navigation';
export var OCTAGON = 'octagon';
export var PACKAGE = 'package';
export var PAPERCLIP = 'paperclip';
export var PAUSE_CIRCLE = 'pause-circle';
export var PAUSE = 'pause';
export var PEN_TOOL = 'pen-tool';
export var PERCENT = 'percent';
export var PHONE_CALL = 'phone-call';
export var PHONE_FORWARDED = 'phone-forwarded';
export var PHONE_INCOMING = 'phone-incoming';
export var PHONE_MISSED = 'phone-missed';
export var PHONE_OFF = 'phone-off';
export var PHONE_OUTGOING = 'phone-outgoing';
export var PHONE = 'phone';
export var PIE_CHART = 'pie-chart';
export var PLAY_CIRCLE = 'play-circle';
export var PLAY = 'play';
export var PLUS_CIRCLE = 'plus-circle';
export var PLUS_SQUARE = 'plus-square';
export var PLUS = 'plus';
export var POCKET = 'pocket';
export var POWER = 'power';
export var PRINTER = 'printer';
export var RADIO = 'radio';
export var REFRESH_CCW = 'refresh-ccw';
export var REFRESH_CW = 'refresh-cw';
export var REPEAT = 'repeat';
export var REWIND = 'rewind';
export var ROTATE_CCW = 'rotate-ccw';
export var ROTATE_CW = 'rotate-cw';
export var RSS = 'rss';
export var SAVE = 'save';
export var SCISSORS = 'scissors';
export var SEARCH = 'search';
export var SEND = 'send';
export var SERVER = 'server';
export var SETTINGS = 'settings';
export var SHARE_2 = 'share-2';
export var SHARE = 'share';
export var SHIELD_OFF = 'shield-off';
export var SHIELD = 'shield';
export var SHOPPING_BAG = 'shopping-bag';
export var SHOPPING_CART = 'shopping-cart';
export var SHUFFLE = 'shuffle';
export var SIDEBAR = 'sidebar';
export var SKIP_BACK = 'skip-back';
export var SKIP_FORWARD = 'skip-forward';
export var SLACK = 'slack';
export var SLASH = 'slash';
export var SLIDERS = 'sliders';
export var SMARTPHONE = 'smartphone';
export var SMILE = 'smile';
export var SPEAKER = 'speaker';
export var SQUARE = 'square';
export var STAR = 'star';
export var STOP_CIRCLE = 'stop-circle';
export var SUN = 'sun';
export var SUNRISE = 'sunrise';
export var SUNSET = 'sunset';
export var TABLE = 'table';
export var TABLET = 'tablet';
export var TAG = 'tag';
export var TARGET = 'target';
export var TERMINAL = 'terminal';
export var THERMOMETER = 'thermometer';
export var THUMBS_DOWN = 'thumbs-down';
export var THUMBS_UP = 'thumbs-up';
export var TOGGLE_LEFT = 'toggle-left';
export var TOGGLE_RIGHT = 'toggle-right';
export var TOOL = 'tool';
export var TRASH_2 = 'trash-2';
export var TRASH = 'trash';
export var TRELLO = 'trello';
export var TRENDING_DOWN = 'trending-down';
export var TRENDING_UP = 'trending-up';
export var TRIANGLE = 'triangle';
export var TRUCK = 'truck';
export var TV = 'tv';
export var TWITCH = 'twitch';
export var TWITTER = 'twitter';
export var TYPE = 'type';
export var UMBRELLA = 'umbrella';
export var UNDERLINE = 'underline';
export var UNLOCK = 'unlock';
export var UPLOAD_CLOUD = 'upload-cloud';
export var UPLOAD = 'upload';
export var USER_CHECK = 'user-check';
export var USER_MINUS = 'user-minus';
export var USER_PLUS = 'user-plus';
export var USER_X = 'user-x';
export var USER = 'user';
export var USERS = 'users';
export var VIDEO_OFF = 'video-off';
export var VIDEO = 'video';
export var VOICEMAIL = 'voicemail';
export var VOLUME_1 = 'volume-1';
export var VOLUME_2 = 'volume-2';
export var VOLUME_X = 'volume-x';
export var VOLUME = 'volume';
export var WATCH = 'watch';
export var WIFI_OFF = 'wifi-off';
export var WIFI = 'wifi';
export var WIND = 'wind';
export var X_CIRCLE = 'x-circle';
export var X_OCTAGON = 'x-octagon';
export var X_SQUARE = 'x-square';
export var X = 'x';
export var YOUTUBE = 'youtube';
export var ZAP_OFF = 'zap-off';
export var ZAP = 'zap';
export var ZOOM_IN = 'zoom-in';
export var ZOOM_OUT = 'zoom-out';
