import i18next from 'i18next';
import en from './../../lang/en.json';
import nl from './../../lang/nl.json';
import ua from './../../lang/ua.json';
import fr from './../../lang/fr.json';
import de from './../../lang/de.json';
import es from './../../lang/es.json';


import { appState } from './appState';

// Language resource mapping
export const languageResource = {
    en: { translation: en },
    nl: { translation: nl },
    ua: { translation: ua},
    fr: { translation: fr },
    de: { translation: de },
    es: { translation: es },
};

// Initialization function
export const initLocales = async () => {
    await i18next.init({
        lng: 'en',
        fallbackLng: ['nl', 'fr', 'de', 'es', 'ua'],
        resources: languageResource,
        nsSeparator: false, // ':',
        keySeparator: '.',
        initImmediate: false,
        interpolation: {
            escapeValue: false,
            skipOnVariables: false,
        },
    });
};

// Event listener for i18next initialization
i18next.on('initialized', () => {
    const language = appState.restore('language') ?? 'en';
    changeLang(language);
});

// Function to change language
export const changeLang = (lang) => {
    appState.language = lang;
    i18next.changeLanguage(lang, (error) => {
        if (error) {
            appState.toast(error);
        }
    });

    appState.store('language', lang);

    setTimeout(() => {
        document.body.setAttribute('lang', lang);
    }, 300);
};

// Function to get the current language
export const currentLanguage = () => {
    return i18next.language;
};

// Helper functions for translation
export const _t = (key, options) => i18next.t(key, options);
export const _ct = _t;
