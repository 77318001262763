import m from 'mithril';
import { Select } from 'construct-ui';
import { appState } from '../lib/appState';
import { _t } from '../lib/i18n';
import sjefIconSettings from '@sjefapp/sjeficons/icons/sjefIconSettings';

export class DropdownSettings {
    oninit(vnode) {
        vnode.state.isPopoverOpen = false;
        vnode.state.togglePopover = () => {
            vnode.state.isPopoverOpen = !vnode.state.isPopoverOpen;
        };

        document.addEventListener('click', (event) => {
            if (!vnode.dom.contains(event.target)) {
                vnode.state.isPopoverOpen = false;
                m.redraw();
            }
        });
    }

    view(vnode) {
        const options = appState.options;
        const onSelectChange = vnode.attrs.onSelectChange;
        const isPopoverOpen = vnode.state.isPopoverOpen;

        return m('.custom-popover-container', [
            m('', {
                onclick: () => { vnode.state.isPopoverOpen = true; m.redraw(); },
            },
                m(sjefIconSettings),
            ),
            isPopoverOpen &&
            m('.modalSettingsContainer', [
                m('.modalSettingsOverlay', {
                    onclick: () => { vnode.state.isPopoverOpen = false; m.redraw(); },
                }),
                m('.modalSettingsDialog', [
                    m('.modalSettingsBody', [
                        m('.modalSettingsHeader',
                            m('h3.modalSettingsTitle', _t(`lang.modal.batchAction.settings.makeTimer`)),
                            m('.modalSettingsOptions',
                                options.map((option) => {
                                    return m('.selectOption', {
                                        class: parseInt(option) === parseInt(appState.selectedOption) ? 'selected' : '',
                                        
                                        onclick: () => {
                                            localStorage.setItem('selectedOption', option);
                                            onSelectChange(option);
                                            vnode.state.isPopoverOpen = false;
                                            m.redraw();
                                        }, 
                                    }, option)
                                }), 
                            )
                        ),
                        m('.modalSettingsHeader', [
                            m('h3.modalSettingsTitle', _t(`lang.modal.batchAction.settings.printChoice`)),
                            m('.modalSettingsOptionsLarge',
                                appState.action.map((action) => {
                                    return m('.selectOption', {
                                        class: action === appState.selectedAction ? 'selected' : '',
                                        onclick: () => {
                                            localStorage.setItem('selectedAction', action);
                                            appState.selectedAction = action;
                                            vnode.state.isPopoverOpen = false;
                                            m.redraw();
                                        }, 
                                    }, _t(`lang.options.${action}`))
                                }),
                            ),
                            
                        ]),
                    ]),
                ]),
            ]),
        ]);
    }
}
