import m from 'mithril';
import { appState } from '../lib/appState';
import { DropdownSettings } from './dropdownSettings';
import { ModalDropDownSettings } from './modalDropDownsettinds';
import { _t } from '../lib/i18n';

const Switch = {
    view: function (vnode) {
        return m('label.switchMode', [
            m('input[type=checkbox]', {
                checked: vnode.attrs.checked,
                onchange: vnode.attrs.onchange,
            }),
            m('span.slider1.round1'),
        ]);
    },
};

export class Header {
    constructor() {
        this.switchChecked = false;
    }

    view(vnode) {
        const currentTab = vnode.attrs.currentTab;
        const mode = vnode.attrs.mode;
        const serveValue = appState.selectedAction;
        const tab = appState.tabClass;
        const route = m.route.get(); // Отримання поточного маршруту

        // Умова для відображення Switch: відображаємо тільки для крім '/modeServe'
        const showSwitch = route !== '/modeServe' && route !== '/modeCustomer' && (tab === 'open' || tab === 'ready');
        const showServe = route !== '/modeCustomer' && (tab === 'open' || tab === 'ready');

        return m('.header', [
            m(
                '.leftPart',
                showSwitch
                    ? m(
                          '.modeSwitcher',
                          _t('lang.headlines.kitchen_screen'),
                          m(Switch, {
                              onchange: (event) => {
                                  const newMode = event.target.checked ? 'serve' : 'kitchen';
                                  const newTab = event.target.checked ? 'ready' : 'open';
                                  vnode.attrs.onModeChange(newTab, newMode);
                              },
                          })
                      )
                    : null
            ),
            m('.middlePart', {
                onclick: () => {
                    m.route.set('/kdsMode');
                },
            }),

            m('.rightPart', [
                showServe
                    ? m(DropdownSettings, {
                          options: appState.options,
                          selectedValue: appState.selectedOption,
                          serveValue: appState.action,
                          selectedAction: appState.selectedAction,
                          onSelectChange: (value) => {
                              appState.selectedOption = value;
                              appState.toast('Selected value: ' + appState.selectedOption);
                              m.redraw();
                          },
                          serveChange: (serveValue) => {
                              appState.selectedAction = serveValue;
                              appState.toast('MODE: ' + serveValue);
                              m.redraw();
                          },
                      })
                    : null,
            ]),
        ]);
    }
}
