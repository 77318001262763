import { appState } from '../lib/appState';

export const audioService = {
    playAudio: () => {
        if (!appState.data.orders.open) return;

        let notification = document.getElementById('notificationSound');

        const currentOpen = appState.data.orders.open.length;
        const previousOpen = appState.data.previousOpen || 0;

        if (typeof currentOpen !== 'undefined' && typeof previousOpen !== 'undefined' && currentOpen > previousOpen) {
            notification.play();
        }

        appState.data.previousOpen = currentOpen;
    },
};
