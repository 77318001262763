import m from 'mithril';
import moment from 'moment';

import { _t } from '../lib/i18n.js';
import { appState } from '../lib/appState.js';
import { orderService } from '../services/orderService.js';

import { Header } from '../components/header.js';
import { OrderList } from '../components/orderList.js';
import { SidebarOrders } from '../components/SidebarOrders.js';
import { ModalBatchAction } from '../components/modalBatchAction.js';

let ids = [];
let options = [];
let currentTab = 'open';

export class ModeKitchen {
    orderList = new OrderList();
    tab = currentTab;
    loaded_at = moment().format('HH:mm:ss');
    mode = 'kitchen';

    setTab(tab) {
        this.tab = tab;
        appState.actions.reloadOrders();
    }

    setMode(mode) {
        this.mode = mode;
        m.redraw();
    }

    view() {
        const tabClass = [this.tab].join(' ');
        appState.tabClass = tabClass;

        return m('.webSiteVersion', { class: tabClass }, [
            m(Header, {
                currentClass: this.class,
                currentTab: this.tab,
                settingsActive: true,
                onModeChange: (newTab, newMode) => {
                    this.setTab(newTab);
                    this.setMode(newMode);
                },
                onSelectChange: async (newNumber) => {
                    options = newNumber;
                },
            }),
            m('', [
                m(this.orderList, {
                    mode: this.mode,
                    currentTab: this.tab,
                    orders: appState.data.orders[this.tab] || [],
                    options: options,
                    onChangeId: async (newIds) => {
                        ids = newIds;
                    },
                }),
                this.tab === 'open' && !appState.isMobile ? m(SidebarOrders) : null,
                m('.footer', [
                    !appState.isMobile ? m('.footerTime', m('.footerTimeInner', _t('lang.headlines.orders_loaded_at') + ' ' + this.loaded_at)) : null,
                    this.mode === 'kitchen'
                        ? m('.footerNavBar', [
                              m(
                                  'h3.openOrderFooter',
                                  {
                                      class: this.tab === 'open' ? 'active' : '',
                                      onclick: () => {
                                          this.setTab('open');
                                          this.setMode('kitchen');
                                      },
                                  },
                                  [_t('lang.buttons.open'), `(${appState.data.orders.open?.length || 0})`]
                              ),
                              m(
                                  'h3.parkedOrderFooter',
                                  {
                                      class: this.tab === 'parked' ? 'active' : '',
                                      onclick: () => {
                                          this.setTab('parked');
                                          this.setMode('kitchen');
                                      },
                                  },
                                  [_t('lang.buttons.parked'), `(${appState.data.orders.parked?.length || 0})`]
                              ),
                              m(
                                  'h3.readyFooter',
                                  {
                                      class: this.tab === 'recentready' ? 'active' : '',
                                      onclick: () => {
                                          this.setTab('recentready');
                                          this.setMode('kitchen');
                                      },
                                  },
                                  [_t('lang.buttons.ready'), `(${appState.data.orders.recentready?.length || 0})`]
                              ),
                              //   m(
                              //       'h3.servedFooter',
                              //       {
                              //           class: this.tab === 'recentready' ? 'active' : '',
                              //           onclick: () => {
                              //               this.setTab('recentready');
                              //               this.setMode('kitchen');
                              //           },
                              //       },
                              //       [_t('lang.buttons.served'), `(${appState.data.orders.recentready?.length || 0})`]
                              //   ),
                          ])
                        : null,
                    !appState.isMobile
                        ? m('.header-title', [
                              this.tab === 'open' && [
                                  m(ModalBatchAction, {
                                      ids: this.orderList.getSelectedOrdersIds(),
                                      action: 'prepare',
                                      callback: () => {
                                          this.orderList.clearSelectedOrdersIds();
                                      },
                                  }),
                                  m(ModalBatchAction, {
                                      ids: this.orderList.getSelectedOrdersIds(),
                                      action: 'ready',
                                      callback: () => {
                                          this.orderList.clearSelectedOrdersIds();
                                      },
                                  }),
                              ],
                              this.tab !== 'open' && [
                                  m(ModalBatchAction, {
                                      ids: this.orderList.getSelectedOrdersIds(),
                                      action: 'serve',
                                      callback: () => {
                                          this.orderList.clearSelectedOrdersIds();
                                      },
                                  }),
                              ],
                          ])
                        : null,
                ]),
            ]),
        ]);
    }
}

// On document load, call appState.actions.init
document.addEventListener('DOMContentLoaded', async () => {
    if (appState.token) {
        await appState.actions.init();
    }
});

const main = document.getElementById('main');
m.mount(main, ModeKitchen);
