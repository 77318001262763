import m from 'mithril';

import { appState } from '../lib/appState';

import { apiService } from './apiService';

export const authService = {
    login: async (clientCode, terminalCode, terminalPincode) => {
        try {
            const payload = {
                client_code: clientCode,
                terminal_code: terminalCode,
                terminal_pincode: terminalPincode,
            };

            const response = await apiService.post('auth/login', payload, false);

            if (response.error) {
                apiService.handleError('A0010', response.error);

                appState.toast('An error occurred while trying to get token');
                return response;
            }

            // TODO: move to appStateService
            appState.token = response.data.token;
            appState.terminalCode = response.data.terminal_code;
            localStorage.setItem('token', appState.token);
            localStorage.setItem('terminalCode', response.data.terminal_code);

            return response;
        } catch (error) {
            console.error(error);


            return {
                success: false,
                error: 'error occurred',
            };
        } 
    },

    logout: async () => {
        try {
            if (appState.token) {
                const response = await apiService.post('logout');

                if (response.error) {
                    console.error(response.error);
                    apiService.handleError('A0011', response.error);
                }
            }

            // TODO: move to appStateService
            authService.clearAppState();

            m.route.set('/login');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    },

    clearAppState: () => {
        appState.token = null;
        appState.interval = null;
        clearInterval(appState.intervalId);
        localStorage.removeItem('token');
    },
};
