{
    "lang": {
        "status": {
            "open": "Ouvert",
            "serve": "Servir",
            "ready": "Prêt",
            "prepare": "Préparer",
            "parked": "En attente",
            "welcome": "Bienvenue",
            "in preparation": "En préparation"
        },
        "buttons": {
            "serve": "Servir",
            "served": "Servi",
            "open": "Ouvrir",
            "ready": "Prêt",
            "prepare": "Préparer",
            "login": "Connexion",
            "logout": "Déconnexion",
            "reopen": "Rouvrir",
            "close": "Fermer",
            "yes_prepare": "Oui, préparer",
            "yes_ready": "Oui, prêt",
            "parked": "En attente",
            "recent": "Récent",
            "yes_serve": "Oui, servir"
        },
        "sorting": {
            "default": "Par défaut",
            "priority": "Filtrer par priorité",
            "alphabetical": "Ordre alphabétique",
            "quantity": "Quantité",
            "entryOrder": "Ordre d'entrée"
        },
        "options": {
            "serve": "Servir",
            "serveAndPrint": "Servir et imprimer"
        },
        "headlines": {
            "speak_with_server": "Serveur",
            "order_screen": "Mode client",
            "serve_screen": "Mode service",
            "kitchen_screen": "Mode cuisine",
            "make_your_choice": "Faites votre choix",
            "select_language": "Sélectionnez la langue",
            "in_preparation": "En préparation",
            "parked": "En attente",
            "open": "Ouvert",
            "ready": "Prêt",
            "recentready": "Récent",
            "orders_loaded_at": "Commandes chargées à",
            "ready_to_serve": "Prêt à servir",
            "open_orders": "Commandes ouvertes",
            "parked_orders": "Commandes en attente",
            "consolidated_orders": "Commandes consolidées",
            "recently_complated_orders": "Commandes récemment prêtes",
            "login_to_your_kds": "Connectez-vous à votre KDS",
            "prepare_selected_orders": "Préparer les commandes sélectionnées",
            "all_selected_orders": "Êtes-vous sûr de vouloir préparer toutes les commandes sélectionnées",
            "ready_selected": "Commandes sélectionnées prêtes",
            "all_selected_orders_to_ready": "Êtes-vous sûr de vouloir marquer toutes les commandes sélectionnées comme prêtes",
            "serve_selected": "Servir les commandes sélectionnées",
            "all_selected_orders_to_serve": "Êtes-vous sûr de vouloir servir toutes les commandes sélectionnées"
        },
        "fields": {
            "bedrijfs_code": "Code de l'entreprise",
            "terminal_code": "Code terminal",
            "pincode": "Code PIN"
        },
        "modal": {
            "batchAction": {
                "prepare": {
                    "title": "Préparer les commandes",
                    "buttonLabel": "préparer",
                    "text": "Êtes-vous sûr de vouloir préparer toutes les commandes sélectionnées"
                },
                "ready": {
                    "title": "Commandes prêtes",
                    "buttonLabel": "prêt",
                    "text": "Êtes-vous sûr de vouloir marquer toutes les commandes sélectionnées comme prêtes"
                },
                "serve": {
                    "title": "Servir les commandes",
                    "buttonLabel": "servir",
                    "text": "Êtes-vous sûr de vouloir servir toutes les commandes sélectionnées"
                }
            }
        }
    }
}
