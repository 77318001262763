import moment from 'moment';
import { appState } from '../lib/appState';
// const PRINT_URL ='http://192.168.79.195/StarWebPRNT/SendMessage'
const PRINT_URL =process.env.PRINTER_BASE_URL;


export const printService = {
    printOrder: async function(order,){
        const loaded_at = moment().format('DD-MM-YY HH:mm');
        const timestamp = moment(order.attributes.timestamp).format('DD-MM-YY HH:mm');
        const orderTable =
            order.attributes.table_label !== null
                ? `${order.attributes.order_label} / ${order.attributes.table_label}`
                : `${order.attributes.order_label}`;

        const orderDataLines = order.attributes.lines.map((line) => {
            const kitchenGroceriesList = Object.entries(line.kitchen_groceries)
                .map(([key, value]) => `${key}: \n${value}`)
                .join('\n');
            return `${Math.round(line.quantity)}x - ${line.description}\n${kitchenGroceriesList}`;
        });
        const orderData = orderDataLines.join('\n');
        const builder = new StarWebPrintBuilder();
        let request = '';
        request += builder.createInitializationElement();
        request += builder.createTextElement({ characterspace: 2 });
        request += builder.createAlignmentElement({ position: 'left' });
        request += builder.createTextElement({
            data: timestamp,
            width: 1,
            height: 1,
        });

        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({
            data: orderTable,
            width: 2,
            height: 2,
        });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createAlignmentElement({ position: 'left' });
        request += builder.createTextElement({
            data: orderData,
            width: 1,
            height: 2,
        });

        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({
            data: loaded_at,
            width: 1,
            height: 1,
        });

        request += builder.createCutPaperElement({ feed: true, type: 'full' });

        await this.print(request); 
    },

    print: async function(request) {  
        const printer = new StarWebPrintTrader({
            url: PRINT_URL
        });

        printer.onReceive = () => {
            appState.toast('Print successful:');
        };

        printer.onError = () => {
            appState.toast('Print error:');
        };

        printer.sendMessage({ request: request });
    },
};
