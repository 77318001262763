
/* eslint:disable */
import m from 'mithril';

class sjefIconReceipt {
    view({ attrs: { classes = [], size = "24", strokeWidth = "2", color = "currentColor", fill = "none", onclick = void(0), ...htmlAttrs  }}){
        return m('.sjefIcon.sjefIconReceipt',{
                onclick,
                class  : ['icon-sjefIcon2Fa', ...classes].join(" "),
                ...htmlAttrs
            },
            m('svg[xmlns=http://www.w3.org/2000/svg].icon-svg', {
                width  : size || "24",
                height : size || "24",
                stroke : color || "currentColor",
                fill   : fill || "none",
                "stroke-width": strokeWidth || "2",
                "viewBox": "0 0 24 24",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
            }, m.trust('<path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2" />'))
        )
    }
}
export default sjefIconReceipt;

