export var Classes = {
    // Modifiers
    XS: 'cui-xs',
    SM: 'cui-sm',
    LG: 'cui-lg',
    XL: 'cui-xl',
    PRIMARY: 'cui-primary',
    NEGATIVE: 'cui-negative',
    POSITIVE: 'cui-positive',
    WARNING: 'cui-warning',
    ACTIVE: 'cui-active',
    DISABLED: 'cui-disabled',
    LOADING: 'cui-loading',
    BASIC: 'cui-basic',
    OUTLINED: 'cui-outlined',
    ROUNDED: 'cui-rounded',
    READONLY: 'cui-readonly',
    SELECTED: 'cui-selected',
    INTERACTIVE: 'cui-interactive',
    ELEVATION: 'cui-elevation',
    HIDDEN: 'cui-hidden',
    // Headings
    H1: 'cui-h1',
    H2: 'cui-h2',
    H3: 'cui-h3',
    H4: 'cui-h4',
    H5: 'cui-h5',
    H6: 'cui-h6',
    // Utility
    ALIGN: 'cui-align',
    ALIGN_RIGHT: 'cui-align-right',
    ALIGN_LEFT: 'cui-align-left',
    FLUID: 'cui-fluid',
    TEXT_MUTED: 'cui-text-muted',
    TEXT_DISABLED: 'cui-text-disabled',
    FOCUS_DISABLED: 'cui-focus-disabled',
    COMPACT: 'cui-compact',
    // Component
    BREADCRUMB: 'cui-breadcrumb',
    BREADCRUMB_ITEM: 'cui-breadcrumb-item',
    BREADCRUMB_SEPERATOR: 'cui-breadcrumb-seperator',
    BUTTON: 'cui-button',
    BUTTON_LABEL: 'cui-button-label',
    BUTTON_SUBLABEL: 'cui-button-sublabel',
    BUTTON_GROUP: 'cui-button-group',
    BUTTON_ICON: 'cui-button-icon',
    CARD: 'cui-card',
    CARD_INTERACTIVE: 'cui-card-interactive',
    CALLOUT: 'cui-callout',
    CALLOUT_HEADER: 'cui-callout-header',
    CALLOUT_CONTENT: 'cui-callout-content',
    CALLOUT_ICON: 'cui-callout-icon',
    CALLOUT_DISMISS_ICON: 'cui-callout-dismiss',
    CHECKBOX: 'cui-checkbox',
    COLLAPSE: 'cui-collapse',
    COLLAPSE_BODY: 'cui-collapse-body',
    CONTEXT_MENU: 'cui-context-menu',
    CONTROL: 'cui-control',
    CONTROL_INDICATOR: 'cui-control-indicator',
    CONTROL_GROUP: 'cui-control-group',
    CUSTOM_SELECT: 'cui-custom-select',
    CUSTOM_SELECT_TRIGGER: 'cui-custom-select-trigger',
    CUSTOM_SELECT_INPUT: 'cui-custom-select-input',
    CUSTOM_SELECT_HIDDEN: 'cui-custom-select-hidden',
    DIALOG: 'cui-dialog',
    DIALOG_CLOSE_BUTTON: 'cui-dialog-close-button',
    DIALOG_CONTENT: 'cui-dialog-content',
    DIALOG_HEADER: 'cui-dialog-header',
    DIALOG_BODY: 'cui-dialog-body',
    DIALOG_FOOTER: 'cui-dialog-footer',
    DRAWER: 'cui-drawer',
    DRAWER_CONTENT: 'cui-drawer-content',
    EMPTY_STATE: 'cui-empty-state',
    EMPTY_STATE_ICON: 'cui-empty-state-icon',
    EMPTY_STATE_CONTENT: 'cui-empty-state-content',
    EMPTY_STATE_HEADER: 'cui-empty-state-header',
    EMPTY_STATE_FILL: 'cui-empty-state-fill',
    FORM: 'cui-form',
    FORM_GROUP: 'cui-form-group',
    FORM_LABEL: 'cui-form-label',
    GRID: 'cui-grid',
    COL: 'cui-col',
    INPUT_FILE: 'cui-input-file',
    INPUT_FILE_CONTENT: 'cui-input-file-content',
    INPUT_FILE_TEXT: 'cui-input-file-text',
    INPUT_FILE_BUTTON: 'cui-input-file-button',
    ICON: 'cui-icon',
    ICON_ACTION: 'cui-icon-action',
    INPUT: 'cui-input',
    INPUT_GROUP: 'cui-input-group',
    INPUT_POPOVER: 'cui-input-popover',
    INPUT_POPOVER_CONTENT: 'cui-input-popover-content',
    INPUT_SELECT: 'cui-input-select',
    LIST: 'cui-list',
    LIST_ITEM: 'cui-list-item',
    LIST_ITEM_LABEL: 'cui-list-item-label',
    LIST_ITEM_CONTENT_LEFT: 'cui-list-item-content-left',
    LIST_ITEM_CONTENT_RIGHT: 'cui-list-item-content-right',
    MENU: 'cui-menu',
    MENU_ITEM: 'cui-menu-item',
    MENU_DIVIDER: 'cui-menu-divider',
    MENU_HEADING: 'cui-menu-heading',
    OVERLAY: 'cui-overlay',
    OVERLAY_CONTENT: 'cui-overlay-content',
    OVERLAY_BACKDROP: 'cui-overlay-backdrop',
    OVERLAY_OPEN: 'cui-overlay-open',
    OVERLAY_INLINE: 'cui-overlay-inline',
    OVERLAY_SCROLL_CONTAINER: 'cui-overlay-scroll-container',
    POPOVER: 'cui-popover',
    POPOVER_OPEN: 'cui-popover-open',
    POPOVER_CONTENT: 'cui-popover-content',
    POPOVER_ARROW: 'cui-popover-arrow',
    POPOVER_TRIGGER_ACTIVE: 'cui-popover-trigger-active',
    POPOVER_BACKDROP: 'cui-popover-backdrop',
    POPOVER_DISSMISS: 'cui-popover-dismiss',
    POPOVER_MENU: 'cui-popover-menu',
    PORTAL: 'cui-portal',
    QUERY_LIST: 'cui-query-list',
    QUERY_LIST_CHECKMARK: 'cui-query-list-checkmark',
    QUERY_LIST_EMPTY: 'cui-query-list-empty',
    QUERY_LIST_INITIAL: 'cui-query-list-initial',
    QUERY_LIST_MESSAGE: 'cui-query-list-message',
    RADIO: 'cui-radio',
    RADIO_GROUP: 'cui-radio-group',
    SELECT: 'cui-select',
    SELECT_ARROW: 'cui-select-arrow',
    SELECT_LIST: 'cui-select-list',
    SPINNER: 'cui-spinner',
    SPINNER_CONTENT: 'cui-spinner-content',
    SPINNER_ICON: 'cui-spinner-icon',
    SPINNER_MESSAGE: 'cui-spinner-message',
    SPINNER_FILL: 'cui-spinner-fill',
    SPINNER_ACTIVE: 'cui-spinner-active',
    SPINNER_BG: 'cui-spinner-bg',
    SWITCH: 'cui-switch',
    TABLE: 'cui-table',
    TABLE_BORDERED: 'cui-table-bordered',
    TABLE_STRIPED: 'cui-table-striped',
    TABLE_INTERACTIVE: 'cui-table-interactive',
    TAG: 'cui-tag',
    TAG_REMOVABLE: 'cui-tag-removable',
    TAG_INPUT: 'cui-tag-input',
    TAG_INPUT_VALUES: 'cui-tag-input-values',
    TABS: 'cui-tabs',
    TABS_BORDERED: 'cui-tabs-bordered',
    TABS_ITEM: 'cui-tabs-item',
    TEXT_AREA: 'cui-text-area',
    TOAST: 'cui-toast',
    TOAST_MESSAGE: 'cui-toast-message',
    TOASTER: 'cui-toaster',
    TOASTER_INLINE: 'cui-toaster-inline',
    TOOLTIP: 'cui-tooltip',
    TREE: 'cui-tree',
    TREE_NODE: 'cui-tree-node',
    TREE_NODE_LIST: 'cui-tree-node-list',
    TREE_NODE_CONTENT: 'cui-tree-node-content',
    TREE_NODE_CARET: 'cui-tree-node-caret',
    TREE_NODE_CARET_OPEN: 'cui-tree-node-caret-open',
    TREE_NODE_CARET_CLOSED: 'cui-tree-node-caret-closed',
    TREE_NODE_CARET_NONE: 'cui-tree-node-caret-none',
    TREE_NODE_SELECTED: 'cui-tree-node-selected',
    TREE_NODE_EXPANDED: 'cui-tree-node-expanded',
    TREE_NODE_LABEL: 'cui-tree-node-label',
    TREE_NODE_CONTENT_RIGHT: 'cui-tree-node-content-right',
    TREE_NODE_CONTENT_LEFT: 'cui-tree-node-content-left'
};
