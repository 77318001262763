{
    "lang": {
        "status": {
            "open": "Open",
            "serve": "Serve",
            "served": "Served",
            "ready": "Ready",
            "prepare": "Prepare",
            "inpreparation": "Prepare",
            "parked": "Parked",
            "welcome": "Welcome",
            "in preparation": "In preparation"
        },
        "buttons": {
            "serve": "Serve",
            "served": "Served",
            "open": "Open",
            "ready": "Ready",
            "prepare": "Prepare",
            "login": "Login",
            "logout": "Logout",
            "reopen": "Reopen",
            "close": "Close",
            "yes_prepare": "Yes, prepare",
            "yes_ready": "Yes, ready",
            "parked": "Parked",
            "recent": "Recent",
            "yes_serve": "Yes, serve",
            "split": "Split"
        },
        "sorting": {
            "default": "Default",
            "priority": "Filter by priority",
            "alphabetical": "Alphabetical order",
            "quantity": "Quantity",
            "entryOrder": "Entry order"
        },
        "options": {
            "serve": "Serve",
            "serveAndPrint": "Serve and Print"
        },
        "headlines": {
            "speak_with_server": "Server",
            "order_screen": "Customer mode",
            "serve_screen": "Serve mode",
            "kitchen_screen": "Kitchen mode",
            "make_your_choice": "Make your choice",
            "select_language": "Select language",
            "in_preparation": "In preparation",
            "parked": "Parked",
            "open": "Open",
            "ready": "Ready",
            "recentready": "Recent",
            "orders_loaded_at": "Orders loaded at",
            "ready_to_serve": "Ready to serve",
            "consolidated_orders": "Consolidated orders",
            "open_orders": "Open orders",
            "parked_orders": "Parked orders",
            "recently_complated_orders": "Recently ready orders",
            "login_to_your_kds": "Login to your KDS",
            "prepare_selected_orders": "Prepare selected orders",
            "all_selected_orders": "Are you sure to prepare all selected orders",
            "ready_selected": "Ready selected orders",
            "all_selected_orders_to_ready": "Are you sure to set all selected orders to ready",
            "serve_selected": "Serve selected orders",
            "all_selected_orders_to_serve": "Are you sure to set all selected orders to serve"
        },
        "fields": {
            "bedrijfs_code": "Company code",
            "terminal_code": "Terminal code",
            "pincode": "Pincode"
        },
        "modal": {
            "batchAction": {
                "prepare": {
                    "title": "Prepare Orders",
                    "buttonLabel": "prepare",
                    "text": "Are you sure to prepare all selected orders"
                },
                "ready": {
                    "title": "Ready Orders",
                    "buttonLabel": "ready",
                    "text": "Are you sure to set all selected orders to ready"
                },
                "serve": {
                    "title": "Serve Orders",
                    "buttonLabel": "serve",
                    "text": "Are you sure to set all selected orders to serve"
                },
                "settings": {
                    "makeTimer": "Set time setting",
                    "forOrder": "for All Open Order",
                    "printChoice": "Set print setting",
                    "yourPrintChoice": "you can choice just 'serve'or 'serve and print' "
                }
            }
        }
    }
}
