
/* eslint:disable */
import m from 'mithril';

class sjefIconSettings {
    view({ attrs: { classes = [], size = "24", strokeWidth = "2", color = "currentColor", fill = "none", onclick = void(0), ...htmlAttrs  }}){
        return m('.sjefIcon.sjefIconSettings',{
                onclick,
                class  : ['icon-sjefIcon2Fa', ...classes].join(" "),
                ...htmlAttrs
            },
            m('svg[xmlns=http://www.w3.org/2000/svg].icon-svg', {
                width  : size || "24",
                height : size || "24",
                stroke : color || "currentColor",
                fill   : fill || "none",
                "stroke-width": strokeWidth || "2",
                "viewBox": "0 0 24 24",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
            }, m.trust('<path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" /><circle cx="12" cy="12" r="3" />'))
        )
    }
}
export default sjefIconSettings;

