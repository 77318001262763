import m from 'mithril';
import { appState } from '../lib/appState';
import { _t } from '../lib/i18n';
import sjefIconChevronsDown from '@sjefapp/sjeficons/icons/sjefIconChevronsDown';
import sjefIconChevronsUp from '@sjefapp/sjeficons/icons/sjefIconChevronsUp';

// Sidebar component that displays consolidated orders in their order of occurrence
export class SidebarOrders {
    constructor() {
        this.sortCriteria = 'default'; // Default sorting criteria
        this.sortOrder = 'asc'; // Default sorting order
        this.isOpen = false; // Flag to control dropdown visibility
    }

    // Consolidates orders to count each product quantity
    consolidateOrders(orders) {
        return orders.reduce((productQuantities, order) => {
            order.attributes.lines.forEach((line) => {
                const productName = line.description;
                const quantity = Math.round(line.quantity);
                productQuantities[productName] = (productQuantities[productName] || 0) + quantity;
            });
            return productQuantities;
        }, {});
    }

    // Sort product quantities based on selected criteria and order
    sortProductQuantities(productQuantities) {
        const entries = Object.entries(productQuantities);

        switch (this.sortCriteria) {
            case 'priority':
                // Implement priority-based sorting logic here
                break;
            case 'alphabetical':
                entries.sort((a, b) => {
                    return this.sortOrder === 'asc' ? a[0].localeCompare(b[0]) : b[0].localeCompare(a[0]);
                });
                break;
            case 'quantity':
                entries.sort((a, b) => {
                    return this.sortOrder === 'asc' ? a[1] - b[1] : b[1] - a[1];
                });
                break;
            case 'entryOrder':
            default:
                // Maintain default order of entry
                // No sorting needed since `productQuantities` is already in entry order
                break;
        }

        return entries;
    }

    // Handle click on SVG for changing sort criteria
    handleClickSortCriteria(criteria) {
        this.sortCriteria = criteria;
       
        m.redraw(); // Redraw to reflect the change
    }

    // Handle click on SVG for changing sort order
    handleClickSortOrder() {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        m.redraw(); 
    }

    // Renders the view of the sidebar component
    view() {
        // Consolidate orders from appState
        const productQuantities = this.consolidateOrders(appState.data.orders.open || []);
        
        // Sort product quantities based on current sorting criteria and order
        const sortedProductQuantities = this.sortProductQuantities(productQuantities);

        const sortOptions = [
            'default',
            'priority',
            'alphabetical',
            'quantity',
            'entryOrder'
        ];
        
        // Sorting button
        const sortButton = m('button.sortButton', {
            onclick: () => {
                this.handleClickSortOrder(); 
            }
        }, this.sortOrder === 'asc' ? m(sjefIconChevronsUp) : m(sjefIconChevronsDown));

        // Custom select dropdown with SVG
        const customSelect = m('.custom-select-container', {
            
        }, [
            m('.consolidatedFilter',
                m('div.selected-option', [
                    m('span.selected-text', {
                        onclick: () => {
                            this.isOpen = !this.isOpen;
                            m.redraw(); 
                        }
                    }, 
                        _t(`lang.sorting.${this.sortCriteria}`)
                    ),
                ]),
                sortButton,
            ),
            this.isOpen && m('.options', 
                sortOptions.map(option =>
                    m('div.option', {
                        class: this.sortCriteria === option ? 'active' : '',
                        onclick: () => this.handleClickSortCriteria(option)
                    }, _t(`lang.sorting.${option}`))
                )
            )]
        );
        return m('.consolidatedOrders', [
            m('.consolidatedOrderMain', [
                customSelect,
                m('h3', _t('lang.headlines.consolidated_orders')),
                m('.orders', 
                    sortedProductQuantities.map(([productName, quantity]) => {
                        return m('.consolidatedOrder', `${quantity} x - ${productName}`);
                    })
                ),
            ]),
        ]);
    }
}
