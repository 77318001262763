import m from 'mithril';

import { authService } from '../services/authService';
import { _t } from '../lib/i18n';
import { appState } from '../lib/appState';

import { switchDarkMode } from '../components/switchDarkMode';
import { switchLanguage } from '../components/switchLanguage';
import sjefIconToolsKitchen2 from '@sjefapp/sjeficons/icons/sjefIconToolsKitchen2';
import sjefIconChefHat from '@sjefapp/sjeficons/icons/sjefIconChefHat';
import sjefIconLicense from '@sjefapp/sjeficons/icons/sjefIconLicense';

let thema = false;
export class KdsMode {
    constructor() {
        // this.languageService = new LanguageService();
    }

    view(vnode) {
        return m('.kdsModePage', [
            m('.modalKDSHeader', [
                m(switchLanguage),
                m(
                    '.flex',
                    m(
                        'button.logout',
                        {
                            onclick: async () => {
                                await authService.logout();
                            },
                        },
                        _t('lang.buttons.logout')
                    ),
                    m(switchDarkMode)
                ),
            ]),

            m('.modalChoiceBody', [
                m('h3.modalChoiceTitle', _t('lang.headlines.make_your_choice')),
                m('.modalDescription', [
                    m(
                        '.order1.order',
                        _t('lang.headlines.order_screen'),
                        m(
                            'button.customerButton',
                            {
                                onclick: () => {
                                    m.route.set('/modeCustomer');
                                },
                            },
                            m(sjefIconLicense)
                        )
                    ),
                    m(
                        '.order2.order',
                        _t('lang.headlines.serve_screen'),
                        m(
                            'button.kitchenButton.customerButton',
                            {
                                onclick: () => {
                                    m.route.set('/modeServe');
                                },
                            },
                            m(sjefIconToolsKitchen2)
                        )
                    ),
                    m(
                        '.order3.order',
                        _t('lang.headlines.kitchen_screen'),
                        m(
                            'button.customerButton',
                            {
                                onclick: () => {
                                    m.route.set('/modeKitchen');
                                },
                            },
                            m(sjefIconChefHat)
                        )
                    ),
                ]),
                // m('.modalChoiceFooter', [
                //     m(
                //         'button.logout',
                //         {
                //             onclick: async () => {
                //                 await authService.logout();
                //             },
                //         },
                //         _t('lang.buttons.logout')
                //     ),
                // ]),
            ]),
        ]);
    }
}

const main = document.getElementById('main');

m.mount(main, KdsMode);
