import m from 'mithril';
import { appState } from '../lib/appState';

// Define the switchDarkMode component
export class switchDarkMode {
    // Method to toggle dark mode
    toggle() {
        // Toggle the darkMode property in appState
        appState.darkMode = !appState.darkMode;
        // Set the document's class name based on the new darkMode value
        document.documentElement.className = appState.darkMode ? 'theme-dark' : 'theme-light';
    }

    // Render method for the component
    view(vnode) {
        // Render the Mithril virtual DOM structure for the switch
        return m('label.switch', [
            // Input checkbox for toggling dark mode
            m('input[type=checkbox]', {
                onclick: () => this.toggle(), // Call toggle method on click
                id: 'slider',
            }),
            // Span element for the slider
            m('span.slider.round'),
        ]);
    }
}
