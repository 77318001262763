{
    "lang": {
        "status": {
            "open": "Відкрито",
            "serve": "Обслуговувати",
            "ready": "Готово",
            "prepare": "Готувати",
            "parked": "Припарковано",
            "welcome": "Ласкаво просимо",
            "in preparation": "Готується"
        },
        "buttons": {
            "serve": "Обслуговувати",
            "open": "Відкрити",
            "ready": "Готово",
            "prepare": "Готувати",
            "login": "Увійти",
            "logout": "Вийти",
            "reopen": "Відкрити знову",
            "close": "Закрити",
            "yes_prepare": "Так, готувати",
            "yes_ready": "Так, готово",
            "parked": "Припарковано",
            "recent": "Нещодавно",
            "yes_serve": "Так, обслуговувати"
        },
        "sorting": {
            "default": "За замовчуванням",
            "priority": "Фільтр за пріоритетом",
            "alphabetical": "Алфавітний порядок",
            "quantity": "Кількість",
            "entryOrder": "Порядок надходження"
        },
        "options": {
            "serve": "Подавати",
            "serveAndPrint": "Подавати та друкувати"
        },
        "headlines": {
            "speak_with_server": "Сервер",
            "order_screen": "Режим клієнта",
            "serve_screen": "Режим обслуговування",
            "kitchen_screen": "Кухонний режим",
            "make_your_choice": "Зробіть свій вибір",
            "select_language": "Виберіть мову",
            "in_preparation": "Готується",
            "parked": "Припарковано",
            "open": "Відкрито",
            "ready": "Готово",
            "recentready": "Нещодавно",
            "orders_loaded_at": "Замовлення завантажено о",
            "ready_to_serve": "Готово до обслуговування",
            "open_orders": "Відкриті замовлення",
            "parked_orders": "Припарковані замовлення",
            "recently_complated_orders": "Нещодавно завершені замовлення",
            "login_to_your_kds": "Увійдіть у ваш KDS",
            "prepare_selected_orders": "Готувати вибрані замовлення",
            "all_selected_orders": "Ви впевнені, що хочете готувати всі вибрані замовлення",
            "ready_selected": "Вибрані замовлення готові",
            "all_selected_orders_to_ready": "Ви впевнені, що хочете позначити всі вибрані замовлення як готові",
            "serve_selected": "Обслуговувати вибрані замовлення",
            "all_selected_orders_to_serve": "Ви впевнені, що хочете обслуговувати всі вибрані замовлення"
        },
        "fields": {
            "bedrijfs_code": "Код компанії",
            "terminal_code": "Код терміналу",
            "pincode": "PIN-код"
        },
        "modal": {
            "batchAction": {
                "prepare": {
                    "title": "Готувати замовлення",
                    "buttonLabel": "готувати",
                    "text": "Ви впевнені, що хочете готувати всі вибрані замовлення"
                },
                "ready": {
                    "title": "Замовлення готові",
                    "buttonLabel": "готово",
                    "text": "Ви впевнені, що хочете позначити всі вибрані замовлення як готові"
                },
                "serve": {
                    "title": "Обслуговувати замовлення",
                    "buttonLabel": "обслуговувати",
                    "text": "Ви впевнені, що хочете обслуговувати всі вибрані замовлення"
                }
            }
        }
    }
}
