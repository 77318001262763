import m from 'mithril';
import routes from './routes';
import { appState } from './lib/appState';
import { initLocales, currentLanguage } from './lib/i18n';
import '../css/style.css';

const updateMobileState = () => {
    appState.isMobile = window.innerWidth <= 768;
    m.redraw();
};

// Update mobile state on window resize
window.addEventListener('resize', updateMobileState);

// Initialize the app once the DOM content is loaded
document.addEventListener('DOMContentLoaded', () => {
    updateMobileState();
    routes.Routes();
    initLocales('en');
    appState.language = currentLanguage();
});
