import m from 'mithril';

import { appState } from '../lib/appState';
import { _t } from '../lib/i18n';
import { Header } from '../components/header';

export class ModeCustomer {
    view(vnode) {
        const renderOrderLabel = (label) => {
            return [m('.customerPreparePart', label.slice(0, 5)), label.length > 5 && m('.customerPreparePart', label.slice(5))];
        };

        const renderPrepareOrders = () => {
            return appState.data.orders.open
                ?.filter((order) => order.attributes.status === 'in preparation')
                .map((order) => renderOrderLabel(order.attributes.order_label));
        };

        const renderReadyOrders = () => {
            return appState.data.orders.ready
                ?.filter((order) => order.attributes.status === 'ready')
                .map((order) => m('.customerReadyPart', order.attributes.order_label));
        };

        return m('.divdiv', [
            m(Header, {}),
            m('.mainCustomerPart', [
                m('.upCustomerPart', [m('.customerLeftPart', _t('lang.buttons.prepare')), m('.customerRightPart', _t('lang.buttons.ready'))]),
                m('.customerPart', [m('.customerPrepareOrders', renderPrepareOrders()), m('.customerready', renderReadyOrders())]),
            ]),
        ]);
    }
}

const main = document.getElementById('main');

m.mount(main, ModeCustomer);
