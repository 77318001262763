import { appState } from '../lib/appState';
import { apiService } from './apiService';
import moment from 'moment';
// TODO: POSITION: use the new position endpoint
// the new position is calculated with the average of the two surrounding positions -> const position = Math.round((orderJobLeftOfNewPoition + orderJobRightOfNewPoition) / 2)
// example: Math.round((1719404450288457 + 1719404460500560) / 2)
// /orderjobs/{orderjob_id}/position payload = { position: 1719404455394509 }
// additional: only the open orders are allowed to be moved!
export const orderService = {
    moved: async (id, orderJobLeftOfNewPosition, orderJobRightOfNewPosition) => {
        try {
            // Обчислити нову позицію
            const position = Math.round((orderJobLeftOfNewPosition + orderJobRightOfNewPosition) / 2);

            // console.log('Moved Item ID:', id);
            // console.log('Calculated new position:', position);
            // console.log('Left Position:', orderJobLeftOfNewPosition);
            // console.log('Right Position:', orderJobRightOfNewPosition);

            const payload = { position };

            const response = await apiService.post(`orderjobs/${id}/position`, payload);

            // console.log(`Moved order with id ${id} to new position ${position}`);
        } catch (error) {
            console.error('Error in moved function:', error);
            appState.toast('Error in moved function: ' + error.message);
        }
    },

    // Implementation of getOrders function to fetch open orders
    getOrders: async (status) => {
        const response = await apiService.get(`orderjobs?status=${status}`);
        if (!response.ok) {
            throw new Error('Failed to fetch orders.');
        }
        return await response.json();
    },

    // Other methods like toStatus, toStatusBatch, etc. remain unchanged...

    get: async (type) => {
        if (!type) return;

        try {
            const response = await apiService.get(`orderjobs/${type}`);

            if (response.error) {
                appState.toast('An error occurred while trying to get token');
                appState.toast('A0020', response.error);

                return response;
            }

            const orderData = await response.data.data;
            switch (type) {
                case 'recentready':
                    appState.data.orders.recentReady = orderData;
                    break;
                case 'parked':
                    appState.data.orders.parked = orderData;
                    break;
                case 'open':
                    appState.data.orders.open = orderData;
                    break;
                case 'ready':
                    appState.data.orders.ready = orderData;
                    break;
                default:
                    appState.toast('Unknown order type:' + type);
            }

            return {
                success: true,
                data: orderData,
            };
        } catch (error) {
            apiService.handleError('A0021', error);

            return {
                success: false,
                error: 'error occurred',
            };
        }
    },

    toStatus: async (fromStatus, toStatus, id) => {
        try {
            const response = await apiService.post(`orderjobs/${id}/${toStatus}`);
            // console.log(fromStatus, toStatus, id, response);

            if (response.error) {
                appState.toast('An error occurred');
                apiService.handleError('A0030', response.error);

                return response;
            }

            const orderIndex = appState.data.orders[fromStatus].findIndex((order) => order.id === id);
            const order = response.data.data;

            if (orderIndex > -1) {
                if (toStatus === 'prepare') {
                    appState.data.orders[fromStatus][orderIndex] = order;
                }
                if (toStatus === 'ready') {
                    appState.data.orders[fromStatus].splice(orderIndex, 1);
                    appState.data.orders['ready'].push(order);
                    appState.data.orders['recentready'].push(order);
                }
                if (toStatus === 'park') {
                    appState.data.orders[fromStatus].splice(orderIndex, 1);
                    appState.data.orders['parked'].push(order);
                }
                if (toStatus === 'serve') {
                    appState.data.orders[fromStatus].splice(orderIndex, 1);
                }
                if (toStatus === 'reopen') {
                    if (fromStatus === 'ready' || fromStatus === 'served') {
                        fromStatus = 'recentready';
                    }
                    appState.data.orders[fromStatus].splice(orderIndex, 1);
                    appState.data.orders['open'].push(order);
                }
            }

            return {
                success: true,
            };
        } catch (error) {
            console.log(error);
            apiService.handleError('A0031', error);
            // appState.toast(`Error making authenticated request for ${endpoint} action:` + error);
            return error;
        }
    },

    toStatusBatch: async (status, ids = []) => {
        try {
            if (ids.length === 0) {
                return;
            }

            const response = await apiService.post(`orderjobs/batch/${status}`, { ids });

            if (response.error) {
                appState.toast('An error occurred');
                apiService.handleError('A0040', response.error);

                return response;
            }
            await appState.actions.reloadOrders();

            return {
                success: true,
                data: response.data.data,
            };
        } catch (error) {
            apiService.handleError('A0041', error);

            appState.toast(`Error making authenticated request for ${endpoint} action:` + error);
            return error;
        }
    },

    makeTimestampPostRequest: async (id, timestamp) => {
        try {
            return await orderService.postOrderAction('timestamp', id, { timestamp });
        } catch (error) {
            apiService.handleError('A0050', error);

            appState.toast('Error making timestamp post request:' + error);
            throw error;
        }
    },
};
