import m from 'mithril';
import i18next from 'i18next';

import { appState } from '../lib/appState';

export class switchLanguage {
    isOpen = false;

    // Method to toggle the menu's visibility
    toggleMenu() {
        this.isOpen = !this.isOpen;
    }

    // Method to set the language
    setLanguage(lang) {
        i18next.changeLanguage(lang, (error) => {
            if (error) {
                appState.toast(error);
            }
        });

        appState.language = lang;
        appState.store('language', lang);

        this.toggleMenu();
    }
    view() {
        return m(
            '.modalLanguage',
            m('.language-menu-container', [
                m('button.menu-toggle', { onclick: () => this.toggleMenu() }, appState.language),
                this.isOpen
                    ? m('ul.language-menu', [
                          m(
                              'li.language',
                              m('a', { onclick: () => this.setLanguage('en') }, [
                                  m(
                                      'svg',
                                      {
                                          width: '60',
                                          height: '60',
                                          viewBox: '0 -4 28 28',
                                          xmlns: 'http://www.w3.org/2000/svg',
                                          fill: 'none',
                                      },
                                      [
                                          m('g', { 'clip-path': 'url(#clip0_503_2952)' }, [
                                              m('rect', { width: '28', height: '20', rx: '2', fill: 'white' }),
                                              m(
                                                  'mask',
                                                  {
                                                      id: 'mask0_503_2952',
                                                      style: { maskType: 'alpha' },
                                                      maskUnits: 'userSpaceOnUse',
                                                      x: '0',
                                                      y: '0',
                                                      width: '28',
                                                      height: '20',
                                                  },
                                                  [m('rect', { width: '28', height: '20', rx: '2', fill: 'white' })]
                                              ),
                                              m('g', { mask: 'url(#mask0_503_2952)' }, [
                                                  m('rect', { width: '28', height: '20', fill: '#0A17A7' }),
                                                  m('path', {
                                                      fillRule: 'evenodd',
                                                      clipRule: 'evenodd',
                                                      d: 'M-1.28244 -1.91644L10.6667 6.14335V-1.33333H17.3334V6.14335L29.2825 -1.91644L30.7737 0.294324L21.3263 6.66667H28V13.3333H21.3263L30.7737 19.7057L29.2825 21.9165L17.3334 13.8567V21.3333H10.6667V13.8567L-1.28244 21.9165L-2.77362 19.7057L6.67377 13.3333H2.95639e-05V6.66667H6.67377L-2.77362 0.294324L-1.28244 -1.91644Z',
                                                      fill: 'white',
                                                  }),
                                                  m('path', {
                                                      d: 'M18.668 6.33219L31.3333 -2',
                                                      stroke: '#DB1F35',
                                                      strokeWidth: '0.666667',
                                                      strokeLinecap: 'round',
                                                  }),
                                                  m('path', {
                                                      d: 'M20.0128 13.6975L31.3666 21.3503',
                                                      stroke: '#DB1F35',
                                                      strokeWidth: '0.666667',
                                                      strokeLinecap: 'round',
                                                  }),
                                                  m('path', {
                                                      d: 'M8.00555 6.31046L-3.83746 -1.67099',
                                                      stroke: '#DB1F35',
                                                      strokeWidth: '0.666667',
                                                      strokeLinecap: 'round',
                                                  }),
                                                  m('path', {
                                                      d: 'M9.29006 13.6049L-3.83746 22.3105',
                                                      stroke: '#DB1F35',
                                                      strokeWidth: '0.666667',
                                                      strokeLinecap: 'round',
                                                  }),
                                                  m('path', {
                                                      fillRule: 'evenodd',
                                                      clipRule: 'evenodd',
                                                      d: 'M0 12H12V20H16V12H28V8H16V0H12V8H0V12Z',
                                                      fill: '#E6273E',
                                                  }),
                                              ]),
                                          ]),
                                      ]
                                  ),
                              ])
                          ),
                          // French
                          m(
                            'li.language',
                              m('a', { onclick: () => this.setLanguage('fr') }, [
                                  m(
                                      'svg',
                                      {
                                          width: '60',
                                          height: '60',
                                          viewBox: '0 0 36 36',
                                          xmlns: 'http://www.w3.org/2000/svg',
                                          fill: 'none',
                                      },
                                      [
                                          m('path', { fill: '#ED2939', d: 'M36 27a4 4 0 0 1-4 4h-8V5h8a4 4 0 0 1 4 4v18z' }),
                                          m('path', { fill: '#002495', d: 'M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5H4z' }),
                                          m('path', { fill: '#EEE', d: 'M12 5h12v26H12z' }),
                                      ]
                                  ),
                              ])
                          ),
                          // Spanish
                          m(
                            'li.language',
                              m('a', { onclick: () => this.setLanguage('es') }, [
                                  m(
                                      'svg',
                                      {
                                          width: '60',
                                          height: '60',
                                          viewBox: '0 0 36 36',
                                          xmlns: 'http://www.w3.org/2000/svg',
                                          fill: 'none',
                                      },
                                      [
                                          m('path', {
                                              fill: '#C60A1D',
                                              d: 'M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v18z',
                                          }),
                                          m('path', { fill: '#FFC400', d: 'M0 12h36v12H0z' }),
                                          m('path', { fill: '#EA596E', d: 'M9 17v3a3 3 0 1 0 6 0v-3H9z' }),
                                          m('path', { fill: '#F4A2B2', d: 'M12 16h3v3h-3z' }),
                                          m('path', { fill: '#DD2E44', d: 'M9 16h3v3H9z' }),
                                          m('ellipse', { fill: '#EA596E', cx: '12', cy: '14.5', rx: '3', ry: '1.5' }),
                                          m('ellipse', { fill: '#FFAC33', cx: '12', cy: '13.75', rx: '3', ry: '.75' }),
                                          m('path', { fill: '#99AAB5', d: 'M7 16h1v7H7zm9 0h1v7h-1z' }),
                                          m('path', { fill: '#66757F', d: 'M6 22h3v1H6zm9 0h3v1h-3zm-8-7h1v1H7zm9 0h1v1h-1z' }),
                                      ]
                                  ),
                              ])
                          ),
                          // German
                          m(
                            'li.language',
                              m('a', { onclick: () => this.setLanguage('de') }, [
                                  m(
                                      'svg',
                                      {
                                          width: '60',
                                          height: '60',
                                          viewBox: '0 0 36 36',
                                          xmlns: 'http://www.w3.org/2000/svg',
                                          fill: 'none',
                                      },
                                      [
                                          m('path', { fill: '#FFCD05', d: 'M0 27a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4v-4H0v4z' }),
                                          m('path', { fill: '#ED1F24', d: 'M0 14h36v9H0z' }),
                                          m('path', { fill: '#141414', d: 'M32 5H4a4 4 0 0 0-4 4v5h36V9a4 4 0 0 0-4-4z' }),
                                      ]
                                  ),
                              ])
                          ),
                          // Ukraine 
                          m(
                            'li.language',
                            m('a', { onclick: () => this.setLanguage('ua') }, [
                                m(
                                    'svg',
                                    {
                                        width: '60',
                                        height: '60',
                                        viewBox: '0 0 36 36',
                                        xmlns: 'http://www.w3.org/2000/svg',
                                        'aria-hidden': 'true',
                                        role: 'img',
                                        class: 'iconify iconify--twemoji',
                                        preserveAspectRatio: 'xMidYMid meet',
                                    },
                                    [
                                        m('path', { fill: '#005BBB', d: 'M32 5H4a4 4 0 0 0-4 4v9h36V9a4 4 0 0 0-4-4z' }),
                                        m('path', { fill: '#FFD500', d: 'M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4v-9h36v9z' }),
                                    ]
                                ),
                            ])
                        ),
                        
                          // Dutch
                          m(
                            'li.language',
                              m('a', { onclick: () => this.setLanguage('nl') }, [
                                  m(
                                      'svg',
                                      {
                                          width: '60',
                                          height: '60',
                                          viewBox: '0 0 28 20',
                                          xmlns: 'http://www.w3.org/2000/svg',
                                          fill: 'none',
                                      },
                                      [
                                          m('g', { 'clip-path': 'url(#clip0_503_2742)' }, [
                                              m('rect', {
                                                  x: '0.25',
                                                  y: '0.25',
                                                  width: '27.5',
                                                  height: '19.5',
                                                  rx: '1.75',
                                                  fill: 'white',
                                                  stroke: '#F5F5F5',
                                                  'stroke-width': '0.5',
                                              }),
                                              m(
                                                  'mask',
                                                  {
                                                      id: 'mask0_503_2742',
                                                      style: { maskType: 'alpha' },
                                                      maskUnits: 'userSpaceOnUse',
                                                      x: '0',
                                                      y: '0',
                                                      width: '28',
                                                      height: '20',
                                                  },
                                                  [
                                                      m('rect', {
                                                          x: '0.25',
                                                          y: '0.25',
                                                          width: '27.5',
                                                          height: '19.5',
                                                          rx: '1.75',
                                                          fill: 'white',
                                                          stroke: 'white',
                                                          'stroke-width': '0.5',
                                                      }),
                                                  ]
                                              ),
                                              m('g', { mask: 'url(#mask0_503_2742)' }, [
                                                  m('path', {
                                                      'fill-rule': 'evenodd',
                                                      'clip-rule': 'evenodd',
                                                      d: 'M0 6.66667H28V0H0V6.66667Z',
                                                      fill: '#CA2B39',
                                                  }),
                                                  m('path', {
                                                      'fill-rule': 'evenodd',
                                                      'clip-rule': 'evenodd',
                                                      d: 'M0 20H28V13.3333H0V20Z',
                                                      fill: '#2C56A2',
                                                  }),
                                              ]),
                                          ]),
                                      ]
                                  ),
                              ])
                          ),
                      ])
                    : null,
            ])
        );
    }
}
