{
    "lang": {
        "status": {
            "open": "Offen",
            "serve": "Servieren",
            "ready": "Bereit",
            "prepare": "Vorbereiten",
            "parked": "Geparkt",
            "welcome": "Willkommen",
            "in preparation": "In Vorbereitung"
        },
        "buttons": {
            "serve": "Servieren",
            "served": "Serviert",
            "open": "Öffnen",
            "ready": "Bereit",
            "prepare": "Vorbereiten",
            "login": "Anmelden",
            "logout": "Abmelden",
            "reopen": "Wieder öffnen",
            "close": "Schließen",
            "yes_prepare": "Ja, vorbereiten",
            "yes_ready": "Ja, bereit",
            "parked": "Geparkt",
            "recent": "Kürzlich",
            "yes_serve": "Ja, servieren"
        },
        "sorting": {
            "default": "Standard",
            "priority": "Nach Priorität filtern",
            "alphabetical": "Alphabetische Reihenfolge",
            "quantity": "Menge",
            "entryOrder": "Eingangsreihenfolge"
        },
        "options": {
            "serve": "Bedienen",
            "serveAndPrint": "Bedienen und drucken"
        },
        "headlines": {
            "speak_with_server": "Server",
            "order_screen": "Kundenmodus",
            "serve_screen": "Serviermodus",
            "kitchen_screen": "Küchenmodus",
            "make_your_choice": "Treffen Sie Ihre Wahl",
            "select_language": "Sprache auswählen",
            "in_preparation": "In Vorbereitung",
            "parked": "Geparkt",
            "open": "Offen",
            "ready": "Bereit",
            "recentready": "Kürzlich",
            "orders_loaded_at": "Bestellungen geladen um",
            "ready_to_serve": "Bereit zu servieren",
            "open_orders": "Offene Bestellungen",
            "parked_orders": "Geparkte Bestellungen",
            "consolidated_orders": "Consolidated Bestellungen",
            "recently_complated_orders": "Kürzlich fertige Bestellungen",
            "login_to_your_kds": "Anmelden zu Ihrem KDS",
            "prepare_selected_orders": "Ausgewählte Bestellungen vorbereiten",
            "all_selected_orders": "Sind Sie sicher, dass Sie alle ausgewählten Bestellungen vorbereiten möchten",
            "ready_selected": "Ausgewählte Bestellungen bereit",
            "all_selected_orders_to_ready": "Sind Sie sicher, dass Sie alle ausgewählten Bestellungen als bereit markieren möchten",
            "serve_selected": "Ausgewählte Bestellungen servieren",
            "all_selected_orders_to_serve": "Sind Sie sicher, dass Sie alle ausgewählten Bestellungen servieren möchten"
        },
        "fields": {
            "bedrijfs_code": "Firmencode",
            "terminal_code": "Terminalcode",
            "pincode": "PIN-Code"
        },
        "modal": {
            "batchAction": {
                "prepare": {
                    "title": "Bestellungen vorbereiten",
                    "buttonLabel": "vorbereiten",
                    "text": "Sind Sie sicher, dass Sie alle ausgewählten Bestellungen vorbereiten möchten"
                },
                "ready": {
                    "title": "Bestellungen bereit",
                    "buttonLabel": "bereit",
                    "text": "Sind Sie sicher, dass Sie alle ausgewählten Bestellungen als bereit markieren möchten"
                },
                "serve": {
                    "title": "Bestellungen servieren",
                    "buttonLabel": "servieren",
                    "text": "Sind Sie sicher, dass Sie alle ausgewählten Bestellungen servieren möchten"
                }
            }
        }
    }
}
