{
    "lang": {
        "status": {
            "open": "Open",
            "serve": "Serveren",
            "ready": "Klaar",
            "prepare": "Voorbereiden",
            "parked": "Geparkeerd",
            "welcome": "Welkom",
            "in preparation": "In voorbereiding"
        },
        "buttons": {
            "serve": "Serveren",
            "served": "Geserveerd",
            "open": "Open",
            "ready": "Klaar",
            "prepare": "Voorbereiden",
            "login": "Inloggen",
            "logout": "Uitloggen",
            "reopen": "Heropenen",
            "close": "Sluiten",
            "yes_prepare": "Ja, voorbereiden",
            "yes_ready": "Ja, klaar",
            "parked": "Geparkeerd",
            "recent": "Recent",
            "yes_serve": "Ja, serveren"
        },
        "sorting": {
            "default": "Standaard",
            "priority": "Filter op prioriteit",
            "alphabetical": "Alfabetisch",
            "quantity": "Aantal",
            "entryOrder": "Volgorde van binnenkomst"
        },
        "options": {
            "serve": "Bedienen",
            "serveAndPrint": "Bedienen en afdrukken"
        },
        "headlines": {
            "speak_with_server": "Server",
            "order_screen": "Klantenmodus",
            "serve_screen": "Serveermodus",
            "kitchen_screen": "Keukenmodus",
            "make_your_choice": "Maak uw keuze",
            "select_language": "Selecteer taal",
            "in_preparation": "In voorbereiding",
            "parked": "Geparkeerd",
            "open": "Open",
            "ready": "Klaar",
            "recentready": "Recent",
            "orders_loaded_at": "Bestellingen geladen om",
            "ready_to_serve": "Klaar om te serveren",
            "open_orders": "Open bestellingen",
            "consolidated_orders": "Consolidated orders",
            "parked_orders": "Geparkeerde bestellingen",
            "recently_complated_orders": "Onlangs voltooide bestellingen",
            "login_to_your_kds": "Log in op uw KDS",
            "prepare_selected_orders": "Bereid geselecteerde bestellingen voor",
            "all_selected_orders": "Weet u zeker dat u alle geselecteerde bestellingen wilt voorbereiden",
            "ready_selected": "Geselecteerde bestellingen klaar",
            "all_selected_orders_to_ready": "Weet u zeker dat u alle geselecteerde bestellingen als klaar wilt markeren",
            "serve_selected": "Geselecteerde bestellingen serveren",
            "all_selected_orders_to_serve": "Weet u zeker dat u alle geselecteerde bestellingen wilt serveren"
        },
        "fields": {
            "bedrijfs_code": "Bedrijfscode",
            "terminal_code": "Terminalcode",
            "pincode": "Pincode"
        },
        "modal": {
            "batchAction": {
                "prepare": {
                    "title": "Bestellingen voorbereiden",
                    "buttonLabel": "voorbereiden",
                    "text": "Weet u zeker dat u alle geselecteerde bestellingen wilt voorbereiden"
                },
                "ready": {
                    "title": "Bestellingen klaar",
                    "buttonLabel": "klaar",
                    "text": "Weet u zeker dat u alle geselecteerde bestellingen als klaar wilt markeren"
                },
                "serve": {
                    "title": "Bestellingen serveren",
                    "buttonLabel": "serveren",
                    "text": "Weet u zeker dat u alle geselecteerde bestellingen wilt serveren"
                }
            }
        }
    }
}
