import m from 'mithril';
import { orderService } from '../services/orderService';
import { appState } from '../lib/appState';
import { _t } from '../lib/i18n';

export class ModalBatchAction {
    modalOpen = false;

    // Method to open the modal
    openModal = () => {
        this.modalOpen = true;
    };

    // Method to close the modal
    closeModal = () => {
        this.modalOpen = false;
    };

    // Render method for the component
    view(vnode) {
        const { ids, action, callback } = vnode.attrs;

        // Render the component structure using Mithril's virtual DOM
        return m('.title', [
            m('', [
                // Button to trigger modal opening
                m(
                    '.prepareFooterButton',
                    m(
                        'button',
                        {
                            class: ids.length === 0 ? 'blurred' : '',
                            onclick: async () => {
                                // Check if there are selected ids; if not, return early
                                if (ids.length === 0) {
                                    return;
                                }
                                this.openModal(); // Open the modal
                            },
                        },
                        _t(`lang.buttons.${vnode.attrs.action}`) // Button text localized using _t function
                    )
                ),
            ]),
            // Render modal content if modalOpen is true
            this.modalOpen
                ? m('.modalPRSContainer', [
                      m('.modalPRSOverlay', {
                          onclick: () => {
                              this.closeModal();
                          },
                      }),
                      m('.modalPRSDialog', [
                          // Modal header with title
                          m('.modalPRSHeader', [m('h3.modalPRSTitle', _t(`lang.modal.batchAction.${action}.title`))]),

                          m('.modalPRSBody', [m('span', _t(`lang.modal.batchAction.${action}.text`))]),
                          m('.modalPRSFooter', [
                              m(
                                  'button.modalPRSCloseButton',
                                  {
                                      onclick: () => {
                                          this.closeModal();
                                      },
                                  },
                                  _t('lang.buttons.close')
                              ),
                              m(
                                  'button.modalPRSActionButton',
                                  {
                                      onclick: async () => {
                                          await orderService.toStatusBatch(action, ids);
                                          callback();
                                          this.closeModal();
                                      },
                                  },
                                  _t(`lang.modal.batchAction.${action}.buttonLabel`) // Action button label localized using _t function
                              ),
                          ]),
                      ]),
                  ])
                : '', // Empty string if modalOpen is false
        ]);
    }
}
