import m from 'mithril';
import moment from 'moment';

import { _t } from '../lib/i18n.js';
import { appState } from '../lib/appState.js';

import { Header } from '../components/header.js';
import { OrderList } from '../components/orderList.js';
import { SidebarOrders } from '../components/SidebarOrders.js';
import { ModalBatchAction } from '../components/modalBatchAction.js';

let ids = [];
let options = [];
let serveValue = 'serve';  
let currentTab = 'ready';

export class ModeServe {
    orderList = new OrderList();
    loaded_at = moment().format('HH:mm:ss');
    tab = currentTab;
    mode = 'serve';

    setTab(tab) {
        this.tab = tab;
        appState.actions.reloadOrders();
    }

    setMode(mode) {
        this.mode = mode;
        m.redraw();
    }

    view() {
        const tabClass = [this.tab].join(' ');
        // console.log('Current tab class:', tabClass);
        appState.tabClass = tabClass;

        return m(
            '.webSiteVersion',
            { class: tabClass },
            [
                m(Header, {
                    currentClass: this.class,
                    currentTab: this.tab,
                    mode: this.mode,
                    settingsActive: true,
                    onSelectChange: async (newNumber) => {
                        options = newNumber;
                    },
                    serveChange: async (newStatus) => {
                        serveValue = newStatus;
                    },
                    onModeChange: (newTab, newMode) => {
                        this.setTab(newTab);
                        this.setMode(newMode);
                    },
                }),
                m('', [
                    m(this.orderList, {
                        key: this.tab,
                        mode: this.mode,
                        serveValue: serveValue,
                        currentTab: this.tab,
                        orders: appState.data.orders[this.tab] || [],
                        options: options,
                        onChangeId: async (newIds) => {
                            ids = newIds;
                        },
                    }),
                  

                    m('.footer', { key: 'footer' }, [
                        m('.footerTime', 
                            m('.footerTimeInner', _t('lang.headlines.orders_loaded_at') + ' ' + this.loaded_at)
                        ),
                        this.tab ==='open'?
                        m('.footerNavBar', [
                            m(
                                'h3.openOrderFooter',
                                {
                                    class: this.tab === 'open' ? 'active' : '',
                                    onclick: () => {
                                        this.setTab('open');
                                        this.setMode('kitchen')
                                    },
                                },
                                m('',
                                _t('lang.buttons.open'),
                                `(${appState.data.orders.open?.length || 0})`
                            )
                            ),
                            m(
                                'h3.parkedOrderFooter',
                                {
                                    class: this.tab === 'parked' ? 'active' : '',
                                    onclick: () => {
                                        this.setTab('parked');
                                        this.setMode('kitchen')
                                    },
                                },
                                _t('lang.buttons.parked'),
                                `(${appState.data.orders.parked?.length || 0})`
                            ),
                            m(
                                'h3.recentReadyFooter',
                                {
                                    class: this.tab === 'recentready' ? 'active' : '',
                                    onclick: () => {
                                        this.setTab('recentready');
                                        this.setMode('kitchen')
                                    },
                                },
                                _t('lang.buttons.ready'),
                                `(${appState.data.orders.recentReady?.length || 0})`
                            ),
                        ]):null,
                        !appState.isMobile ? m('.header-title', [
                            m(ModalBatchAction, {
                                ids: this.orderList.getSelectedOrdersIds(),
                                action: 'serve',
                                callback: () => {
                                    this.orderList.clearSelectedOrdersIds();
                                },
                            })
                        ]) : null,
                    ]),

                ]),
            ]
        );
    }
}

const main = document.getElementById('main');
m.mount(main, ModeServe);
