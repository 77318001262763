import m from 'mithril';
import moment from 'moment';
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

import { appState } from '../lib/appState.js';
import { _t } from '../lib/i18n.js';
import { orderService } from '../services/orderService.js';
import { ModalOrderDetails } from './modalOrderDetails.js';
import { audioService } from '../services/audioService.js';
import { printService } from '../services/printService.js';

import { ModalBatchAction } from './modalBatchAction';
import sjefIconHandStop from '@sjefapp/sjeficons/icons/sjefIconHandStop.js';
import sjefIconReceipt from '@sjefapp/sjeficons/icons/sjefIconReceipt.js';

export class OrderList {
    orders = [];
    selectedIds = [];
    selectedOrderIndex = null;
    modalDetailsIsOpen = false;

    async oninit() {
        audioService.playAudio();
    }

    getSelectedOrdersIds() {
        return this.selectedIds;
    }

    clearSelectedOrdersIds() {
        this.selectedIds = [];
    }
    view(vnode) {
        const mode = vnode.attrs.mode;
        const currentTab = vnode.attrs.currentTab;
        const orders = appState.data.orders[currentTab] || [];
        const containerClass = currentTab === 'ready' ? '.serve-containers' : '.main-containers';

        return m('.container-wrapper', [
            m('h3', _t(`lang.headlines.${currentTab}`), '  (' + orders.length + ')'),
            m(
                containerClass,
                {
                    class: ['mode-' + mode].join(' '),
                    oncreate: (vnode) => {
                        if (mode === 'kitchen') {
                            Sortable.create(vnode.dom, {
                                animation: 500,
                                disabled: false,
                                handle: '.upPart',
                                onEnd: async (event) => {
                                    const newIndex = event.newIndex;
                                    const oldIndex = event.item.dataset.idx;
                                    const orders = appState.data.orders.open;

                                    if (parseInt(newIndex) === parseInt(oldIndex)) {
                                        return;
                                    }

                                    if (!orders.length) {
                                        return;
                                    }

                                    const id = event.item.dataset.id;
                                    const direction = parseInt(newIndex) < parseInt(oldIndex) ? 'backwards' : 'forwards';

                                    let leftPosition;
                                    let rightPosition;
                                    let leftOrder;
                                    let rightOrder;

                                    if (direction === 'backwards') {
                                        if (newIndex === 0) {
                                            leftPosition = orders[0].attributes.position - 5999;
                                        } else {
                                            leftOrder = orders[newIndex - 1];
                                            leftPosition = leftOrder.attributes.position;
                                        }

                                        rightOrder = orders[newIndex];
                                        rightPosition = rightOrder.attributes.position;
                                    } else if (direction === 'forwards') {
                                        leftOrder = orders[newIndex];
                                        leftPosition = leftOrder.attributes.position;

                                        if (newIndex === orders.length - 1) {
                                            rightPosition = orders[orders.length - 1].attributes.position + 6001;
                                        } else {
                                            rightOrder = orders[newIndex + 1];
                                            rightPosition = rightOrder.attributes.position;
                                        }
                                    }

                                    try {
                                        await orderService.moved(id, leftPosition, rightPosition);

                                        // Update orders after moving

                                        await appState.actions.reloadOrders();
                                        // appState.data.orders.open=orders

                                        // Optionally, you can log or perform other actions after successful move
                                        console.log(`Order ${id} moved successfully.`);
                                    } catch (error) {
                                        appState.toast(`Error moving order: ${error}`);
                                    }
                                },
                            });
                        }
                    },
                },
                orders.map((order, index) => {
                    const isActive = this.selectedIds.includes(order.id);

                    const orderTimestamp = moment(order.attributes.timestamp);
                    const currentTimestamp = moment();
                    const differenceInMinutes = moment.duration(currentTimestamp.diff(orderTimestamp)).asMinutes();
                    const selectedOptionMinutes = parseInt(appState.selectedOption);

                    let borderClass = '';
                    if (differenceInMinutes > selectedOptionMinutes + 10) {
                        borderClass = 'danger';
                    } else if (differenceInMinutes > selectedOptionMinutes) {
                        borderClass = 'almostDanger';
                    }
                    let buttonClass = '';
                    if (order.attributes.status === 'open') {
                        buttonClass = 'open';
                    } else if (order.attributes.status === 'in preparation') {
                        buttonClass = 'prepare';
                    }

                    return m(
                        '.container',
                        {
                            key: currentTab + '-' + order.attributes.position,
                            id: 'container' + index,
                            'data-idx': index,
                            'data-id': order.id,
                            'data-position': order.attributes.position,
                            class: [isActive ? 'active-container' : 'containerA', borderClass].join(' '),
                        },
                        [
                            m('.containerFrame', {}, [
                                m(
                                    '.upPart',
                                    {
                                        class: isActive ? 'true' : 'false',
                                        onclick: () => {
                                            const orderId = order.id;
                                            const indexToRemove = this.selectedIds.indexOf(orderId);

                                            if (indexToRemove === -1) {
                                                this.selectedIds.push(orderId);
                                            } else {
                                                this.selectedIds.splice(indexToRemove, 1);
                                            }

                                            if (typeof vnode.attrs.onChangeId === 'function') {
                                                vnode.attrs.onChangeId(this.selectedIds);
                                            }
                                        },
                                    },
                                    [
                                        m('.idContainer', order.attributes.table_label ? order.attributes.table_label : order.attributes.order_label),
                                        m(
                                            '.orderStatus',
                                            {
                                                class: ['colorOfButton', buttonClass].join(' '),
                                            },
                                            m('.orderStatus', _t('lang.status.' + order.attributes.status))
                                        ),
                                        m('.orderTimestamp', moment(order.attributes.timestamp).format('HH:mm:ss')),
                                    ]
                                ),
                                m('.lowPart', [
                                    m('.lowOrderMain', [
                                        m(
                                            '.orderWrapper',
                                            {
                                                onclick: (event) => {
                                                    event.stopPropagation();
                                                    this.selectedOrderIndex = order.id;
                                                    this.modalDetailsIsOpen = true;
                                                },
                                            },
                                            m(
                                                'ul.order',
                                                order.attributes.lines.map((line, line_index) => {
                                                    const lineContent = [
                                                        // if line.crossed === true add class crossed
                                                        m('li.lowOrderMainOrders', [
                                                            m(
                                                                // if line.crossed === true add class crossed
                                                                'span',
                                                                {
                                                                    class: line.crossed ? 'line-through' : '',
                                                                },
                                                                `${Math.round(line.quantity)} x - ${line.description}`
                                                            ),
                                                            m(
                                                                'ul',
                                                                Object.entries(line.kitchen_groceries).map(([key, value]) => {
                                                                    return m('li.groceries', [
                                                                        m('div.groceriesKey', `${key}: `),
                                                                        m('div.groceriesValue', `${value}`),
                                                                    ]);
                                                                })
                                                            ),
                                                            m('ul.notes', line.notes),
                                                        ]),
                                                    ];
                                                    if (
                                                        line_index > 0 &&
                                                        order.attributes.lines[line_index - 1].display_order !== line.display_order
                                                    ) {
                                                        lineContent.unshift(m('.divov', '------'));
                                                    }
                                                    return lineContent;
                                                })
                                            )
                                        ),
                                        m('.lowOrder', [
                                            ['open', 'in preparation'].includes(order.attributes.status) && mode === 'kitchen'
                                                ? m(
                                                      '.lowOrderRightButton',
                                                      m(
                                                          'button',
                                                          {
                                                              class: ['colorOfButton', buttonClass].join(' '),
                                                              onclick: async () => {
                                                                  if (order.attributes.status === 'open') {
                                                                      await orderService.toStatus('open', 'prepare', order.id);
                                                                  } else if (order.attributes.status === 'in preparation') {
                                                                      await orderService.toStatus('open', 'ready', order.id);
                                                                  }
                                                              },
                                                          },
                                                          order.attributes.status === 'open' ? _t('lang.buttons.prepare') : _t('lang.buttons.ready')
                                                      )
                                                  )
                                                : null,
                                            ['open', 'in preparation'].includes(order.attributes.status) && mode === 'kitchen'
                                                ? m(
                                                      '.lowOrderLeftButton',
                                                      m(
                                                          'button',
                                                          {
                                                              onclick: async () => {
                                                                  await orderService.toStatus('open', 'park', order.id);
                                                              },
                                                          },
                                                          m(sjefIconHandStop)
                                                      )
                                                  )
                                                : null,
                                            ['parked', 'ready', 'served'].includes(order.attributes.status) && mode === 'kitchen'
                                                ? m(
                                                      '.lowOrderRightButton',
                                                      m(
                                                          'button',
                                                          {
                                                              onclick: async () => {
                                                                  await orderService.toStatus(
                                                                      order.attributes.status !== 'served' ? order.attributes.status : 'recentready',
                                                                      'reopen',
                                                                      order.id
                                                                  );
                                                              },
                                                          },
                                                          _t('lang.buttons.reopen')
                                                      )
                                                  )
                                                : null,
                                            ['ready'].includes(order.attributes.status) && mode === 'serve'
                                                ? [
                                                      m(
                                                          '.lowOrderLeftButton',
                                                          m(
                                                              'button',
                                                              {
                                                                  onclick: async () => {
                                                                      printService.printOrder(order);
                                                                  },
                                                              },
                                                              m(sjefIconReceipt)
                                                          )
                                                      ),
                                                      m(
                                                          '.lowOrderRightButton',
                                                          m(
                                                              'button',
                                                              {
                                                                  onclick: async () => {
                                                                      if (order.attributes.status === 'ready') {
                                                                          if (!appState.selectedAction) {
                                                                              return;
                                                                          }

                                                                          if (appState.selectedAction === 'serveAndPrint') {
                                                                              //   console.log('Print and serve', serveValue);
                                                                              printService.printOrder(order);
                                                                              await orderService.toStatus('ready', 'serve', order.id);
                                                                          } else if (appState.selectedAction === 'serve') {
                                                                              //   console.log('Serve only', serveValue);
                                                                              await orderService.toStatus('ready', 'serve', order.id);
                                                                          }
                                                                      }
                                                                  },
                                                              },
                                                              _t('lang.buttons.serve')
                                                          )
                                                      ),
                                                  ]
                                                : null,
                                        ]),
                                    ]),
                                ]),
                            ]),
                            this.modalDetailsIsOpen &&
                                this.selectedOrderIndex === order.id &&
                                (order.attributes.status === 'open' || order.attributes.status === 'in preparation') &&
                                m(ModalOrderDetails, {
                                    order,
                                    closeModal: () => {
                                        this.modalDetailsIsOpen = false;
                                    },
                                }),
                        ]
                    );
                })
            ),
        ]);
    }
}
