import m from 'mithril';
import { appState } from '../lib/appState';

const BASE_URL = process.env.API_BASE_URL;

export const apiService = {
    get: async (endpoint, authenticated = true) => {
        return await apiService.request('GET', endpoint, {}, authenticated);
    },

    post: async (endpoint, data = {}, authenticated = true) => {
        return await apiService.request('POST', endpoint, data, authenticated);
    },

    request: async (method, endpoint, data = {}, authenticated = true) => {
        if (authenticated && !appState.token) {
            appState.toast('No token available. User is logged out. Request not sent.');
            return;
        }

        const url = `${BASE_URL}/${endpoint}`;
        // console.log(url);

        try {
            let headers = {};
            if (authenticated) {
                headers = {
                    Authorization: `Bearer ${appState.token}`,
                };
            }
            const response = await m.request({
                method: method,
                url: url,
                headers: headers,
                body: data,
            });
            return {
                success: true,
                data: response || {},
            };
        } catch (error) {
            appState.toast('Error fetching data:' + error);
            let errorMessage = 'An error occurred';
            if (error.code === 401) {
                errorMessage = 'Unauthorized. Please log in.';
            }
            if (error.code === 403) {
                errorMessage = 'Forbidden. You do not have permission to access this resource.';
            }
            if (error.code === 404) {
                errorMessage = 'Resource not found.';
            }
            if (error.code === 422) {
                errorMessage = 'Invalid data';
            }

            if (error.code === 500) {
                errorMessage = 'Server error';
            }

            return {
                success: false,
                error: errorMessage,
                errorCode: error.code || 0,
            };
        }
    },

    handleError: (code, message) => {
        alert(`${code}: ${message || 'Something went wrong'}`);

        return false;
    },
};
