import { Toaster } from 'construct-ui';
import { orderService } from '../services/orderService';
import m from 'mithril';

export const appState = {
    token: localStorage.getItem('token') || null,
    terminalCode: localStorage.getItem('terminalCode') || null,
    isMobile: false,
    language: 'en', // TODO: get from localStorage
    darkMode: true,
    intervalId: null,
    interval: 10000,
    options: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map(String),
    action: ['serve', 'serveAndPrint'],
    selectedAction: 'serve',
    selectedOption: 10,
    tabClass: [],
    data: {
        orders: {
            open: [],
            parked: [],
            ready: [],
            recentReady: [],
        },
        responseOrder: [],
        selectedOrders: [],
        selectedServeOrders: [],
        previousOpen: [],
    },
    toast: (msg, opts) => {
        let options = {
            key: Date.now(),
            // intent: 'primary',
            // size: 'default',
            timeout: 1500,
            message: msg,
            clearOnEscapeKey: true,
            // position: 'top',
        };
        options = Object.assign(options, opts);
        const toasts = appState.toaster.getToasts(options.key);

        if (toasts.length) {
            appState.toaster.update(options.key, options);
            appState.toaster.show(options);
        } else {
            appState.toaster.show(options);
        }
    },

    toaster: new Toaster(),
    restore: (key, defaultValue = '') => {
        let val = localStorage.getItem('dash:' + key);

        if (!val) return defaultValue;
        try {
            val = JSON.parse(val);
            return val;
        } catch (err) {
            return val;
        }
    },
    store: (key, val) => {
        if (val === null) {
            localStorage.removeItem(`dash:${key}`);
        } else {
            val = JSON.stringify(val);
            localStorage.setItem(`dash:${key}`, val);
        }
    },

    actions: {
        async init(orderTypes = ['open', 'parked', 'recentready', 'ready']) {
            // Clear any existing interval
            if (appState.intervalId) {
                clearInterval(appState.intervalId);
            }

            if (!appState.interval) {
                appState.interval = 10000;
            }

            localStorage.getItem('selectedOption') && (appState.selectedOption = localStorage.getItem('selectedOption'));
            localStorage.getItem('selectedAction') && (appState.selectedAction = localStorage.getItem('selectedAction'));

            // Initialize the orders
            await appState.actions.reloadOrders(orderTypes);

            // Set interval for reloading orders
            appState.intervalId = setInterval(() => appState.actions.reloadOrders(orderTypes), appState.interval);
        },

        reloadOrders: async (orderTypes = ['open', 'parked', 'recentready', 'ready']) => {
            const promises = orderTypes.map((type) => orderService.get(type));
            const responses = await Promise.all(promises);

            responses.forEach((response, index) => {
                if (response) {
                    if (orderTypes[index] === 'open' && response.data && response.data.length > appState.data.orders.open.length) {
                        console.log('new one');
                    }

                    appState.data.orders[orderTypes[index]] = [];
                    appState.data.orders[orderTypes[index]] = response.data;
                }
            });
        },
    },
};

window.appState = appState;
