{
    "lang": {
        "status": {
            "open": "Abierto",
            "serve": "Servir",
            "ready": "Listo",
            "prepare": "Preparar",
            "parked": "Estacionado",
            "welcome": "Bienvenido",
            "in preparation": "En preparación"
        },
        "buttons": {
            "serve": "Servir",
            "served": "Servido",
            "open": "Abrir",
            "ready": "Listo",
            "prepare": "Preparar",
            "login": "Iniciar sesión",
            "logout": "Cerrar sesión",
            "reopen": "Reabrir",
            "close": "Cerrar",
            "yes_prepare": "Sí, preparar",
            "yes_ready": "Sí, listo",
            "parked": "Estacionado",
            "recent": "Reciente",
            "yes_serve": "Sí, servir"
        },
        "sorting": {
            "default": "Predeterminado",
            "priority": "Filtrar por prioridad",
            "alphabetical": "Orden alfabético",
            "quantity": "Cantidad",
            "entryOrder": "Orden de entrada"
        },
        "options": {
            "serve": "Servir",
            "serveAndPrint": "Servir e imprimir"
        },
        "headlines": {
            "speak_with_server": "Servidor",
            "order_screen": "Modo cliente",
            "serve_screen": "Modo servir",
            "kitchen_screen": "Modo cocina",
            "make_your_choice": "Haga su elección",
            "select_language": "Seleccionar idioma",
            "in_preparation": "En preparación",
            "parked": "Estacionado",
            "open": "Abierto",
            "ready": "Listo",
            "recentready": "Reciente",
            "orders_loaded_at": "Pedidos cargados a las",
            "ready_to_serve": "Listo para servir",
            "open_orders": "Pedidos abiertos",
            "parked_orders": "Pedidos estacionados",
            "recently_complated_orders": "Pedidos recientemente listos",
            "login_to_your_kds": "Iniciar sesión en su KDS",
            "prepare_selected_orders": "Preparar pedidos seleccionados",
            "all_selected_orders": "¿Está seguro de que desea preparar todos los pedidos seleccionados?",
            "ready_selected": "Pedidos seleccionados listos",
            "all_selected_orders_to_ready": "¿Está seguro de que desea marcar todos los pedidos seleccionados como listos?",
            "serve_selected": "Servir pedidos seleccionados",
            "all_selected_orders_to_serve": "¿Está seguro de que desea servir todos los pedidos seleccionados?"
        },
        "fields": {
            "bedrijfs_code": "Código de la empresa",
            "terminal_code": "Código del terminal",
            "pincode": "Código PIN"
        },
        "modal": {
            "batchAction": {
                "prepare": {
                    "title": "Preparar pedidos",
                    "buttonLabel": "preparar",
                    "text": "¿Está seguro de que desea preparar todos los pedidos seleccionados?"
                },
                "ready": {
                    "title": "Pedidos listos",
                    "buttonLabel": "listo",
                    "text": "¿Está seguro de que desea marcar todos los pedidos seleccionados como listos?"
                },
                "serve": {
                    "title": "Servir pedidos",
                    "buttonLabel": "servir",
                    "text": "¿Está seguro de que desea servir todos los pedidos seleccionados?"
                }
            }
        }
    }
}
